export const BREAKPOINTS = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1900,
};

const MOBILE_BREAKPOINT_INCLUSIVE = 'md';

function getBp() {
    const width = window.innerWidth;

    return SIZES.reduceRight((bp, size) => bp || (width >= BREAKPOINTS[size] && size), false);
}

const SIZES = Object.keys(BREAKPOINTS);

const touch = !window.matchMedia('(pointer: fine)').matches;

const createViewport = (breakpoint = getBp()) => {
    const idx = SIZES.indexOf(breakpoint);
    const eq = (size) => size === breakpoint;
    const neq = (size) => size !== breakpoint;
    const lt = (size) => idx < SIZES.indexOf(size);
    const gt = (size) => idx > SIZES.indexOf(size);
    const lte = (size) => idx <= SIZES.indexOf(size);
    const gte = (size) => idx >= SIZES.indexOf(size);

    return {
        name: breakpoint,
        is: { eq, neq, lt, gt, lte, gte, mobile: lte(MOBILE_BREAKPOINT_INCLUSIVE), touch },
    };
};

function handleResize() {
    const nextBreakpoint = getBp();

    if (nextBreakpoint !== Viewport.viewport.name) {
        Viewport._viewport = createViewport(nextBreakpoint);

        for (const fn of Viewport._bpListeners) fn(Viewport.viewport);
    }

    for (const fn of Viewport._resizeListeners) fn(window);
}

const Viewport = {
    _count: 0,
    _bpListeners: [],
    _resizeListeners: [],
    _viewport: createViewport(),

    get viewport() {
        return this._viewport;
    },

    get listenerCount() {
        return this._count;
    },

    onChange(cb) {
        if (!cb) return;
        this._bpListeners.push(cb);
        this._count += 1;
        if (this._count === 1) this.setup();
    },

    offChange(cb) {
        if (!cb) return;
        const i = this._bpListeners.indexOf(cb);

        if (i !== -1) {
            this._bpListeners.splice(i, 1);
            this._count -= 1;
        }
        if (!this._count) this.tearDown();
    },

    onResize(cb) {
        if (!cb) return;
        this._resizeListeners.push(cb);
        this._count += 1;
        if (this._count === 1) this.setup();
    },

    offResize(cb) {
        if (!cb) return;
        const i = this._bpListeners.indexOf(cb);

        if (i !== -1) {
            this._bpListeners.splice(i, 1);
            this._count -= 1;
        }
        if (!this._count) this.tearDown();
    },

    setup() {
        handleResize();
        window.addEventListener('resize', handleResize);
    },

    tearDown() {
        window.removeEventListener('resize', handleResize);
    },
};

export default Viewport;
