import $ from 'jquery';
import { Password, ExtForm } from '../utils';
import { matchField, not, combine } from './helpers';

export default class PasswordResetController {
    static init(
        uid = 'PasswordResetForm',
        {
            oldPwId = 'OldPassword',
            pwId = 'NewPassword',
            confPwId = 'ConfirmPassword',
            fieldErrorClass = 'invalid-feedback',
            apiErrorId = 'reset-errormessage',
        } = {}
    ) {
        const fields = [
            { id: oldPwId },
            {
                id: pwId,
                validator: combine(
                    Password.getMultiValidationError.bind(Password),
                    not(matchField(oldPwId, true, true), 'Form.Error.ExistingPasswordMatch')
                ),
            },
            { id: confPwId, validator: matchField(pwId, 'Form.Error.ConfirmPassword', true) },
        ];

        const scrollToTop = false;

        ExtForm.initForm(
            uid,
            fields,
            '/api/customer/UpdatePassword',
            {
                fieldErrorClass,
                apiErrorSelector: `#${apiErrorId}`,
                onComplete: ({ message, enableForm, initForm }) => {
                    enableForm();
                    initForm();
                    $(`#${apiErrorId}`).removeClass('alert-danger d-none').addClass('alert-success').html(message);
                },
                scrollToTop: scrollToTop,
            },
            false
        );
    }
}
