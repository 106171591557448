import get from 'lodash.get';

function coalesce(str, def = '') {
    // eslint-disable-next-line eqeqeq
    return str == null ? def : str;
}

export default class Formatter {
    static currencyFormatters = {};
    static dateFormatters = {};

    static property(str = '', params = {}) {
        const matches = str.match(/\{\{([^}]+)\}\}/gi);

        return matches
            ? matches.reduce(
                  (out, match) => out.replace(match, coalesce(get(params, /\{\{\s*([^\s}]+)\s*\}\}/gi.exec(match)[1]))),
                  str
              )
            : str;
    }

    static getCurrencyFormatter(currencyCode, truncate = false) {
        let currency = (currencyCode || 'USD').toLowerCase();
        let options = { 
            style: 'currency', 
            currency,
        };

        if (truncate) {
            options = { ...options, roundingMode: 'trunc'};
            currency = `${currency}-trunc`
        }

        if (!this.currencyFormatters[currency]) {
            this.currencyFormatters[currency] = new Intl.NumberFormat(undefined, options);
        }

        return this.currencyFormatters[currency];
    }

    static getDateFormatter(dateCode) {
        const date = dateCode.toLowerCase();

        if (!this.dateFormatters[date]) {
            this.dateFormatters[date] = new Intl.DateTimeFormat(dateCode, {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
            });
        }
        return this.dateFormatters[date];
    }

    static currency(amount = 0, format = 'usd', formatter = this.getCurrencyFormatter(format)) {
        if (Array.isArray(amount)) {
            if (amount[0] === amount[1]) {
                return Formatter.currency(amount[0], format, formatter);
            }
            const amounts = [...amount].sort((a, b) => a - b);

            return `${Formatter.currency(amounts[0], format, formatter)} - ${Formatter.currency(amounts[1], format, formatter)}`;
        }


        return formatter.format(amount);
    }

    static dateFormat(
        date = '01/01/2021',
        format = window.navigator.language,
        formatter = this.getDateFormatter(format)
    ) {
        return formatter.format(new Date(date));
    }

    static expDateFormat(mixed, to = 'MM / YY', from = 'MM / YYY') {
        if (!mixed || !to) throw new Error(`Missing required args for expDateFormat: ${mixed} (${to})`);

        const { month, year, months, years } =
            typeof mixed === 'string' && from
                ? Formatter.createMonthYearFormatter(from).parse(mixed)
                : Array.isArray(mixed)
                ? { month: mixed[0], year: mixed[1] }
                : typeof mixed == 'object'
                ? mixed
                : {};

        if (!month || !year) {
            throw new Error(
                `expDateFormat requires first arg to be a date string, a Tuple(Month,Year) or a { month, year } object. (recieved: ${mixed})`
            );
        }

        return Formatter.createMonthYearFormatter(to).format(month, year, months, years);
    }

    static expDateMask(formatStr = 'MM / YY') {
        return Formatter.createMonthYearFormatter(formatStr).mask;
    }

    static createMonthYearFormatter(formatStr) {
        const pieces = formatStr.split(/(MM|M|YYYY|YY)/).filter(Boolean) || [];
        const l = pieces.length;
        let i = -1;

        if (!l) throw new Error(`Invalid format string: ${formatStr}`);

        // eslint-disable-next-line no-plusplus
        while (++i < l) {
            if (!/^(MM|M|YYYY|YY|[^MY*"\\]+)$/.test(pieces[i])) {
                throw new Error(`Could not parse format string: ${formatStr}. Invalid piece: ${pieces[i]}`);
            }
        }

        let masks = pieces.reduce(
            (ms, p) =>
                p === 'M'
                    ? ms.reduce((arr, m) => arr.concat(m + '#', m + '##'), [])
                    : ms.map((m) => m + p.replace(/[MY]/g, '#')),
            ['']
        );

        const maxLen = Math.max(...masks.map((x) => x.length));

        masks = masks.map((m) => m.padEnd(maxLen, '#'));

        const format = (month, year, months = Array(10).fill(month), years = Array(10).fill(year)) => {
            const m = months.slice(0);
            const y = years.slice(0);

            return pieces
                .map((p) =>
                    p === 'M'
                        ? Number(m.pop())
                        : p === 'MM'
                        ? `${m.pop()}`.padStart(2, '0')
                        : p === 'YY'
                        ? `${y.pop()}`.slice(-2)
                        : p === 'YYYY'
                        ? `${y.pop()}`
                        : p
                )
                .join('');
        };

        const parseLoose = (str) => {
            let yIndex = formatStr.indexOf('Y');
            let mIndex = formatStr.indexOf('M');
            let y = '';
            let m = '';

            if (yIndex === -1) yIndex = 999;
            if (mIndex === -1) mIndex = 999;

            if (yIndex !== 999 || mIndex !== 999) {
                const [, n1 = '', n2 = ''] = str.match(/^[^\d]*(\d+)[^\d]*(\d*)(?:$|[^\d].*$)/) || [];

                if (!n2 && n1.length > 2 && (!n1.startsWith('20') || n1.length > 4)) {
                    if (n1.length > 4) {
                        if (n1.startsWith('20')) {
                            [, y = '', m = ''] = n1.match(/^(\d\d\d\d)(\d\d?)$/) || [];
                        } else {
                            [, m = '', y = ''] = n1.match(/^(\d\d?)(\d\d\d\d)$/) || [];
                        }
                    } else if (n1.length === 3) {
                        [, m = '', y = ''] = n1.match(/^(\d)(\d\d)$/) || [];
                    } else if (n1.length === 4) {
                        [, m = '', y = ''] = n1.match(/^(\d\d)(\d\d)$/) || [];
                    }
                } else if (n1.length === 1 || n2.length === 4) {
                    [m, y] = [n1, n2];
                } else if (n2.length === 1 || n1.length === 4) {
                    [y, m] = [n1, n2];
                } else if (yIndex < mIndex) {
                    [y, m] = [n1, n2];
                } else {
                    [m, y] = [n1, n2];
                }
            }

            const month = Number(m || 0);
            const year =
                y.length === 2 ? Math.floor(new Date().getFullYear() / 100) * 100 + Number(y || 0) : Number(y || 0);

            return m && y
                ? { month, year, months: [month], years: [year], formatStr }
                : { month: 0, year: 0, months: [0], years: [0], formatStr };
        };

        const parse = (str) => {
            const { m: months, y: years } = pieces.reduce(
                ({ parsed, m, y }, p) => {
                    let month, year;

                    switch (p) {
                        case 'M':
                            [, month] = str.slice(parsed.length).match(/^(\d\d|\d)/) || [];

                            if (!month)
                                throw new Error(
                                    `Month (M) expected but not found at position ${parsed.length} of ${str}. (format: ${formatStr})`
                                );
                            return { parsed: parsed + month, m: m.concat(Number(month)), y };
                        case 'MM':
                            [, month] = str.slice(parsed.length).match(/^(\d\d)/) || [];

                            if (!month)
                                throw new Error(
                                    `Month (MM) expected but not found at position ${parsed.length} of ${str}. (format: ${formatStr})`
                                );
                            return { parsed: parsed + month, m: m.concat(Number(month)), y };
                        case 'YY':
                            [, year] = str.slice(parsed.length).match(/^(\d\d)/) || [];

                            if (!year)
                                throw new Error(
                                    `Year (YY) expected but not found at position ${parsed.length} of ${str}. (format: ${formatStr})`
                                );
                            return {
                                parsed: parsed + year,
                                m,
                                y: y.concat(Math.floor(new Date().getFullYear() / 100) * 100 + Number(year)),
                            };
                        case 'YYYY':
                            [, year] = str.slice(parsed.length).match(/^(\d\d\d\d)/) || [];

                            if (!year)
                                throw new Error(
                                    `Year (YYYY) expected but not found at position ${parsed.length} of ${str}. (format: ${formatStr})`
                                );
                            return { parsed: parsed + year, m, y: y.concat(Number(year)) };
                        default:
                            return { parsed: parsed + p, m, y };
                    }
                },
                { parsed: '', m: [], y: [] }
            );

            return { month: months[0], year: years[0], months, years, formatStr };
        };

        return { format, parse, parseLoose, mask: masks[0], masks, formatStr };
    }
}
