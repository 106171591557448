import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { APP } from '$env';

export default function SvgIcon({ sheet = '', name = '', className, ...props }) {
    const cls = React.useMemo(() => cx('SvgIcon', className, 'icons', sheet ? `icons-${sheet}` : '', `icon-${name}`), [
        className,
        sheet,
        name,
    ]);

    const href = React.useMemo(
        () =>
            `/Client/${APP}/built/img/spritesheet${sheet ? `-${sheet}` : ''}.svg#icon-${[sheet, name]
                .filter(Boolean)
                .join('-')}`,
        [sheet, name]
    );

    return (
        <svg className={cls} {...props}>
            <use xlinkHref={href} href={href} />
        </svg>
    );
}

SvgIcon.propTypes = {
    sheet: px.string,
    name: px.string.isRequired,
    className: px.string,
};
