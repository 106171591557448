import * as config from '~config/order';

export default {
    lastUpdate: 0,

    isPerformingCartOperation: false,

    changes: [],

    Cart: undefined,

    ValidationIssues: [],

    CatalogEntity: {},

    userMessage: {
        ts: 0,
        message: '',
        success: false,
        type: '',
        position: '',
        translationParams: null,
    },

    paymentTokens: {},

    config: {
        OPEN_CART_ON_ADD_ITEM: config.OPEN_CART_ON_ADD_ITEM,
        SUCCESS_NOTIFICATION_ON_ADD_ITEM: config.SUCCESS_NOTIFICATION_ON_ADD_ITEM,
        SCROLL_TOP_ON_ADD_ITEM: config.SCROLL_TOP_ON_ADD_ITEM,
        USE_TOOLTIP_ON_ADD_ITEM_TO_WISH_LIST: config.USE_TOOLTIP_ON_ADD_ITEM_TO_WISH_LIST,
        SCROLL_TO_TOP: config.SCROLL_TO_TOP,
    },
};
