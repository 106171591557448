export default {
    required: 'Form.Validate.Required.Message',
    confirmationMismatch: 'Form.Validate.ConfirmationMismatch.Message',
    tooShort: 'Form.Validate.ValueTooShort.Message',
    tooLong: 'Form.Validate.ValueTooLong.Message',
    invalidEmail: 'Form.Validate.InvalidEmail.Message',
    invalidCC: 'Form.Validate.InvalidCCNumber.Message',
    invalidCCType: 'Form.Validate.InvalidCCType.Message',
    invalidCCDate: 'Form.Validate.InvalidCCDate.Message',
    invalidCCVerification: 'Form.Validate.InvalidCCVerification.Message',
    expiredCCDate: 'Form.Validate.ExpiredDate.Message',
    duplicateGiftCard: 'Form.Validate.DuplicateGiftCard.Message',
    inactiveGiftCard: 'Form.Validate.InactiveGiftCard.Message',
    expiredGiftCard: 'Form.Validate.ExpiredGiftCard.Message',
    invalidGiftCard: 'Form.Validate.InvalidGiftCard.Message',
    noFundsGiftCard: 'Form.Validate.NoFundsGiftCard.Message',
    invalidPostalCode: 'Form.Validate.InvalidPostalCode.Message',
};
