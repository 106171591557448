import $ from 'jquery';

export default class NewsletterSignupController {
    static createRequestHandler({
        uid = '',
        formId = `SubscriptionForm${uid}`,
        errorId = `subscription-errormessage${uid}`,
        successId = `subscription-successmessage${uid}`,
    } = {}) {
        return function onSubscriptionRequest(result) {
            const form = $(`#${formId}`);
            const errorMessage = $(`#${errorId}`);
            const successMessage = $(`#${successId}`);

            form.removeClass('was-validated');
            if (result.success) {
                form[0].reset();
                errorMessage.addClass('d-none');
                successMessage.removeClass('d-none');
            } else {
                errorMessage.removeClass('d-none');
                successMessage.addClass('d-none');
            }
        };
    }

    static initMailchimpForm({
        submitLabel = 'Submit',
        placeholder = 'Email',
        label = 'Subscribe',
        btnClass = 'btn btn-primary',
    } = {}) {
        $('#mc-embedded-subscribe').addClass(btnClass).val(submitLabel);
        $('#mc_embed_signup_scroll > input[type="email"]').attr('placeholder', placeholder).addClass('form-control');
        $('#mc_embed_signup_scroll > label')
            .addClass('d-flex justify-content-center justify-content-lg-end subheader')
            .text(label);
    }
}
