import { Email, ExtForm } from '../utils';

export default class LoginController {
    static init(
        uid = 'LoginForm',
        {
            emailId = 'Email',
            pwId = 'Password',
            fieldErrorClass = 'invalid-feedback',
            apiErrorId = 'login-errormessage',
        } = {}
    ) {
        const fields = [{ id: emailId, validator: Email.getValidationError.bind(Email) }, { id: pwId }];

        ExtForm.initForm(
            uid,
            fields,
            '/AuthApi/InternalLogin',
            {
                fieldErrorClass,
                apiErrorSelector: `#${apiErrorId}`,
                buttonAlwaysEnabled: true,
            },
            false
        );
    }
}
