const MIN_LENGTH = 6;
const UPPERCASE = true;
const LOWERCASE = true;
const NUMBER = true;
const SYMBOL = true;

export default class Password {
    static isValidMatch(pw, confirmPw) {
        return this.isValid(pw) && this.matches(pw, confirmPw);
    }

    static isValid(str) {
        return !this.getValidationError(str);
    }

    static matches(pw, confirmPw) {
        return !this.getMatchError(pw, confirmPw);
    }

    static getMatchValidationError(pw, confirmPw) {
        return this.getValidationError(pw) || this.getMatchError(confirmPw);
    }

    static getMatchError(pw, confirmPw) {
        return (pw !== confirmPw && 'Form.Error.ConfirmPassword') || null;
    }

    static getValidationError(str) {
        if (!str) return 'Form.Error.Required';
        if (MIN_LENGTH && !this.hasMinLength(str)) return 'Form.Error.PasswordMinLength';
        if (LOWERCASE && !this.hasLowerCase(str)) return 'Form.Error.PasswordLowerCase';
        if (UPPERCASE && !this.hasUpperCase(str)) return 'Form.Error.PasswordUpperCase';
        if (NUMBER && !this.hasNumber(str)) return 'Form.Error.PasswordNumber';
        if (SYMBOL && !this.hasSymbol(str)) return 'Form.Error.PasswordSymbol';
        return null;
    }

    static getMultiValidationError(str) {
        if (!str) return 'Form.Error.Required';
        return [
            MIN_LENGTH && !this.hasMinLength(str) && 'Form.Error.PasswordMinLength',
            LOWERCASE && !this.hasLowerCase(str) && 'Form.Error.PasswordLowerCase',
            UPPERCASE && !this.hasUpperCase(str) && 'Form.Error.PasswordUpperCase',
            NUMBER && !this.hasNumber(str) && 'Form.Error.PasswordNumber',
            SYMBOL && !this.hasSymbol(str) && 'Form.Error.PasswordSymbol',
        ].filter(Boolean);
    }

    static hasMinLength(str) {
        return str?.length >= MIN_LENGTH;
    }

    static hasUpperCase(str) {
        return /[A-Z]/.test(str);
    }

    static hasLowerCase(str) {
        return /[a-z]/.test(str);
    }

    static hasSymbol(str) {
        return /([^\w]|_)/.test(str);
    }

    static hasNumber(str) {
        return /[0-9]/.test(str);
    }
}
