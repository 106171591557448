// Add to cart

export const OPEN_CART_ON_ADD_ITEM = false;

export const SUCCESS_NOTIFICATION_ON_ADD_ITEM = true;

export const AUTO_UPDATE_QTY_DEBOUNCE_DELAY_MS = 2000;

export const CART_LOCATION_HASH = 'shopping-cart';

export const SCROLL_TOP_ON_ADD_ITEM = true;

export const CHECKOUT_PAGE_HASH_CUSTOMER = 'customer';

export const CHECKOUT_PAGE_HASH_SHIPPING = 'shipping';

export const CHECKOUT_PAGE_HASH_PAYMENT = 'payment';

export const USE_TOOLTIP_ON_ADD_ITEM_TO_WISH_LIST = false;

export const SCROLL_TO_TOP = true;
