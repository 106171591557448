import $ from 'jquery';
import { onReady } from 'Common/core';

export default function setupAccordionScroll() {
    onReady(() => {
        $('.accordion.accordion--scroll .collapse').on('shown.bs.collapse', () => {
            const $card = $(this).closest('.card');
            const { top } = $card.offset() || {};

            if (top != null) $('html,body').animate({ scrollTop: top }, 500);
        });
    });
}
