import * as types from './types';
import { Facet, FacetList } from '../../models';

function updateDetails(state, withDetails) {
    return {
        ...state,
        details: state.details
            ? {
                  ...state.details,
                  ...withDetails(state.details),
              }
            : state.details,
    };
}

function updateProduct(state, withProduct) {
    return updateDetails(state, (details) => ({
        Product: {
            ...details.Product,
            ...withProduct(details.Product),
        },
    }));
}

export default {
    [types.SET_SELECTED_CODE]: (state, selectedCode) => ({ ...state, selectedCode }),
    [types.ADD_PRODUCT_ENTRIES]: (state, products = []) => ({
        ...state,
        byId: products
            .reduce((a, p) => (p?.Children?.length ? a.concat(p, p.Children) : a.concat(p)), [])
            .reduce((o, p) => ({ ...o, [p.Code]: p }), state.byId),
    }),
    [types.UPDATE_PRODUCT_ENTRIES_QUANTITY]: (state, updates) => ({
        ...state,
        byId: {
            ...updates.reduce((o, u) => ({ ...o, [u.Code]: {...state.byId[u.Code], Quantity: u.Quantity} }), state.byId)
        }
    }),
    [types.SET_DETAILS]: (state, details) => ({
        ...state,
        details,
        selectedCode: details?.Product?.Code || state.selectedCode,
    }),
    [types.UPDATE_DETAILS]: (state, details) => ({
        ...state,
        details: {
            ...state.details,
            ...details,
        },
        selectedCode: details?.Product?.Code || state.selectedCode,
    }),
    [types.SET_QUANTITY]: (state, quantity) => ({ ...state, quantity }),
    [types.SET_SELECTED_MEDIA]: (state, selectedMedia) => ({ ...state, selectedMedia }),
    [types.SELECT_FACET]: (state, { facet, value } = {}) =>
        updateProduct(state, (product) => ({
            Facets: FacetList.exclusivelyToggle(product.Facets, Facet.attribute(facet), value.Value),
        })),
    [types.SELECT_MULTI_FACET]: (state, { facet, value } = {}) =>
        updateProduct(state, (product) => ({
            Facets: FacetList.toggle(product.Facets, Facet.attribute(facet), value.Value),
        })),
    [types.SELECT_CHILD]: (state, { child } = {}) => ({
        ...state,
        selectedChild: state.selectedChild && state.selectedChild.Code === child.Code ? null : child,
    }),
    [types.DESELECT_ALL_FACET_VALUES]: (state) =>
        updateProduct(state, (product) => ({
            Facets: FacetList.deselectAll(product.Facets),
        })),
    [types.SET_CHILD]: (state, { child = null } = {}) => ({
        ...state,
        selectedChild: child,
    }),
    [types.SELECT_CHILD_ATTRIBUTES]: (state, { child = null } = {}) =>
        updateProduct(state, (product) =>
            product && child
                ? {
                      Facets: product.Facets.reduce((F, f) => {
                          const attribute = child.Attributes.find((a) => a.AttributeName === f.Attribute);

                          return attribute?.Values?.length
                              ? FacetList.exclusivelySelect(F, attribute.AttributeName, attribute.Values[0])
                              : F;
                      }, product.Facets),
                  }
                : product
        ),
};
