import * as types from './types';
import state from './state';
import * as Api from './api';

export function setState(nextState = state) {
    return { type: types.SET_STATE, payload: nextState };
}

export function updateStrings(stringMap = {}) {
    return { type: types.UPDATE_STRINGS, payload: stringMap };
}

export function selectLanguage(code, setDefaultLanguage) {
    return async () => {
        const { returnUrl } = (await Api.selectLanguage(code, setDefaultLanguage)) || {};

        if (returnUrl) window.location = returnUrl;
    };
}
