import * as types from './types';

function updateAddressChanges(state, itemCode, quantity, address) {
    if (!state.changes.find((c) => c.type === 'address')) {
        return {
            ...state,
            changes: state.changes.concat([
                {
                    type: 'address',
                    shipments: [
                        {
                            address: address,
                            lineItems: [
                                {
                                    Code: itemCode,
                                    Quantity: quantity,
                                },
                            ],
                        },
                    ],
                },
            ]),
        };
    }

    const updatedChanges = state.changes.reduce((acc, change) => {
        if (change.type === 'address') {
            let itemMatch = change.shipments.find((s) => s.lineItems.find((item) => item.Code === itemCode));

            if (itemMatch) {
                if (itemMatch.address.Id === address.Id) {
                    return acc;
                }
                itemMatch.address = address;
                return acc;
            }

            let existingShipment = change.shipments.find((shipment) => shipment.address.Id === address.Id);

            if (existingShipment) {
                existingShipment = existingShipment.lineItems.concat([{ Code: itemCode, Quantity: quantity }]);
                return acc;
            }

            change.shipments = change.shipments.concat([
                { address: address, lineItems: [{ Code: itemCode, Quantity: quantity }] },
            ]);
            return acc;
        }

        return acc.concat([change]);
    }, []);

    return {
        ...state,
        changes: updatedChanges,
    };
}

/**
 * Ensures cart holds onto expandable fields when cart update does not have them expanded
 * savedshippingaddresses = SavedShippingAddresses
 * ordertotals = OrderTotals
 * forms = Forms[]
 * savedpayments = Forms[].AvailablePaymentMethods[].SavedPaymentInfo
 * shippingmethods = Forms[].Shipments[].AvailableShippingMethods
 */
function cartMerge(existing, next) {
    return {
        ...existing,
        ...next,
        SavedShippingAddresses: next?.SavedShippingAddresses ?? existing?.SavedShippingAddresses,
        OrderTotals: next?.OrderTotals ?? existing?.OrderTotals,
        Forms:
            !existing?.Forms || existing.Forms.length < next?.Forms?.length
                ? next.Forms
                : existing?.Forms && next?.Forms
                ? existing.Forms.map((form, i) => ({
                      ...form,
                      ...next.Forms[i],
                      AvailablePaymentMethods: next.Forms[i]?.AvailablePaymentMethods?.find(
                          (x) => x.SavedPaymentInfo != null
                      )
                          ? next.Forms[i].AvailablePaymentMethods
                          : form.AvailablePaymentMethods?.find((x) => x.SavedPaymentInfo != null)
                          ? form.AvailablePaymentMethods
                          : next.Forms[i]?.AvailablePaymentMethods ?? form.AvailablePaymentMethods,
                      Shipments:
                          next.Forms[i]?.Shipments && form.Shipments
                              ? next.Forms[i].Shipments.map((shipment, j) => ({
                                    ...form.Shipments[j],
                                    ...shipment,
                                    AvailableShippingMethods:
                                        shipment?.AvailableShippingMethods ??
                                        form.Shipments[j]?.AvailableShippingMethods,
                                }))
                              : next.Forms[i]?.Shipments ?? form.Shipments,
                  }))
                : next?.Forms ?? existing?.Forms,
    };
}

export default {
    [types.SET_STATE]: (state, payload) => ({
        ...state,
        ...payload,
        Cart: payload?.Cart ? cartMerge(state.Cart, payload.Cart) : state.Cart,
        CatalogEntity: {
            ...state.CatalogEntity,
            ...payload?.CatalogEntity,
        },
        isPerformingCartOperation: false,
        lastUpdate: Date.now(),
    }),
    [types.SET_IS_UPDATING]: (state, payload = false) => ({
        ...state,
        isPerformingCartOperation: payload,
    }),
    [types.SET_USER_MESSAGE]: (state, { message, success, ts, type, position, translationParams } = {}) => ({
        ...state,
        userMessage: { message, success, ts, type, position, translationParams },
        lastUpdate: Date.now(),
    }),
    [types.ADD_VALIDATION_ISSUE]: (state, message) => ({
        ...state,
        lastUpdate: Date.now(),
        ValidationIssues: (message ? [message] : []).concat(state.ValidationIssues),
        isPerformingCartOperation: false,
    }),
    [types.CLEAR_VALIDATION_ISSUE]: (state) => ({
        ...state,
        lastUpdate: Date.now(),
        ValidationIssues: [],
        isPerformingCartOperation: false,
    }),
    [types.UPDATE_ITEM_QTY]: (state, { shipmentId, itemCode, nextQty, originalQty } = {}) => ({
        ...state,
        changes: state.changes
            .filter(
                (change) => !(change.type === 'qty' && change.shipmentId === shipmentId && change.itemCode === itemCode)
            )
            .concat(originalQty !== nextQty ? { type: 'qty', shipmentId, itemCode, qty: nextQty } : []),
    }),
    [types.UPDATE_ITEM_ADDRESS]: (state, { itemCode, quantity, address } = {}) =>
        updateAddressChanges(state, itemCode, quantity, address),
    [types.CLEAR_CHANGES]: (state) => ({
        ...state,
        changes: [],
    }),
    [types.ADD_SAVE_ADDRESS]: (state, address) => ({
        ...state,
        Cart: {
            ...state.Cart,
            SavedShippingAddresses: [...state.Cart.SavedShippingAddresses, address],
        },
    }),
    [types.SET_PAYMENT_TOKEN]: (state, { type, token } = {}) => ({
        ...state,
        paymentTokens: {
            ...state.paymentTokens,
            [type]: token,
        },
    }),
    [types.SET_SHIPPING_METHODS]: (state, { shipmentId, methods = [] }) => ({
        ...state,
        Cart: {
            ...state.Cart,
            Forms: state.Cart.Forms.map((form, i) =>
                i
                    ? form
                    : {
                          ...form,
                          Shipments: form.Shipments.map((shipment) =>
                              shipment.ShipmentId === shipmentId
                                  ? {
                                        ...shipment,
                                        AvailableShippingMethods: methods,
                                    }
                                  : shipment
                          ),
                      }
            ),
        },
    }),
    [types.UPDATE_CONFIG]: (state, config) => ({
        ...state,
        config: {
            ...state.config,
            ...config,
        },
    }),
};
