import $ from 'jquery';

export default class ReviewsController {
    static setup() {
        //This sets the text of the label when the user changes from reviews to questions and vice versa
        $('body').on('click', '.yotpo-nav-tab', function onNavTabClick() {
            $('.review-question-header').text($(this).find('span').text());
        });

        //This waits for the Yotpo widget to load before setting the value of the label.
        const lis = $('li.yotpo-nav-tab.yotpo-active').length;
        const check_forNavTabs = function waitForNavTabs() {
            setTimeout(function checkForNavTabs() {
                if (lis == $('li.yotpo-nav-tab.yotpo-active').length) {
                    check_forNavTabs();
                } else {
                    $('div.bottom-line-items').prepend(
                        "<span class='review-question-header'>" +
                            $('li.yotpo-nav-tab.yotpo-active').find('span').text() +
                            '</span>'
                    );
                }
            }, 50);
        };

        check_forNavTabs();
    }
}
