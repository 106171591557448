import { logger } from 'Common/core';
import Api from './Api';

let envKey = null;

export default class Spreedly {
    static processorName = 'Spreedly';

    static isReady() {
        return !!window.spreedly;
    }

    static setup({ EnvKey } = {}) {
        if (!EnvKey) logger.error('Spreedly initialized without an environment key.');
        else envKey = EnvKey;
    }

    static tearDown() {
        envKey = null;
    }

    static async tokenizePayment({
        fullName,
        month,
        year,
        zip,
        phoneNumber,
        firstName,
        lastName,
        cardNumber,
        cardCvv,
    } = {}) {
        if (!envKey) throw new Error('Spreedly processor was never initialized with an environment key!');

        const res = await Api.POST(
            `https://core.spreedly.com/v1/payment_methods.json?environment_key=${envKey}`,
            {
                payment_method: {
                    credit_card: {
                        full_name: fullName,
                        first_name: firstName,
                        last_name: lastName,
                        number: cardNumber,
                        verification_value: cardCvv,
                        month: month.toString(),
                        year: year.toString(),
                        phone_number: phoneNumber,
                        zip,
                    },
                },
            },
            { antiForgery: false, credentials: 'omit', safe: false }
        );

        if (res?.transaction?.succeeded && res.transaction.payment_method?.token) {
            return {
                cardType: res.transaction.payment_method.card_type,
                token: res.transaction.payment_method.token,
            };
        } else if (res?.transaction?.message) {
            throw new Error(res.transaction.message);
        } else if (res?.errors?.length) {
            throw new Error(res.errors[0].message);
        } else {
            throw '';
        }
    }
}
