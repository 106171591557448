import $ from 'jquery';

export function matchField(id, msg, matchCase = false) {
    return (v) => {
        if (!v) return 'Form.Error.Required';

        const cmp = $(`#${id}`).val();
        const valid = matchCase ? v === cmp : v.toLowerCase() === cmp.toLowerCase();

        return valid ? undefined : msg;
    };
}

export function not(validator, errorMessage) {
    return (v) => {
        const msg = validator(v);

        return msg ? undefined : errorMessage ?? msg;
    };
}

export function combine(...validators) {
    return (v) => validators.reduce((msg, validator) => (msg?.length ? msg : validator(v)), undefined);
}
