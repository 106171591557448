import Api from './Api';
import { NODE_ENV } from '$env';

const DEFAULT_ERROR = 'Unknown Error';
const DEFAULT_URL = NODE_ENV?.startsWith('dev')
    ? 'https://cert-xiecomm.paymetric.com/DIeComm/Ajax'
    : 'https://xiecomm.paymetric.com/DIeComm/Ajax';

let callbackUrl = DEFAULT_URL;

export default class Paymetric {
    static processorName = 'Paymetric';

    static setup(options) {
        if (options) this.setUrl(options.XiInterceptUrl || options.XiInterceptCallbackUrl);
    }

    static setUrl(xiecommUrl) {
        callbackUrl = xiecommUrl.match(/\/Ajax$/i)
            ? xiecommUrl
            : xiecommUrl.match(/\/$/)
            ? `${xiecommUrl}Ajax`
            : `${xiecommUrl}/Ajax`;
    }

    static get instance() {
        if (!this.isReady()) {
            throw new Error('Included Paymetric utility, but Paymetric not loaded in header of page.');
        }
        return window.$XIPlugin;
    }

    static isReady() {
        return typeof window.$XIPlugin !== 'undefined';
    }

    static async tokenizePayment({ systemName, cardNumber, merchantGuid } = {}) {
        const { token } = (await Paymetric.getPaymentToken({ cardNumber, systemName, merchantGuid })) || {};

        if (!token) throw '';

        return {
            token,
        };
    }

    static async getAccessToken({ merchantGuid, systemName }) {
        const xml = `<?xml version="1.0"?><AjaxPacketModel />`;
        const res = await Api.POST('/Paymetric/AccessToken', {
            MerchantGuid: merchantGuid,
            Packet: xml,
            PaymentMethodSystemName: systemName,
            SessionRequestType: 2,
        });

        if (res?.success) {
            return res.token;
        } else {
            throw new Error(Array.isArray(res?.error) ? res.error[0] : res?.error || DEFAULT_ERROR);
        }
    }

    static async getPaymentToken({ cardNumber, merchantGuid, systemName } = {}) {
        const xi = this.instance;
        const accessToken = await this.getAccessToken({ merchantGuid, systemName });
        const formData = xi.createJSRequestPacket(merchantGuid, accessToken);
        const cleanCardNumber = cardNumber.replace(/\s/g, '');

        formData.addField(xi.createField('PaymentCreditCard', true, cleanCardNumber));

        await new Promise((res, rej) => {
            xi.ajax({
                url: callbackUrl,
                type: 'POST',
                data: formData,
                success: res,
                error: rej,
            });
        });

        const result = await Api.POST('/Paymetric/PaymentToken', {
            MerchantGUID: merchantGuid,
            AccessToken: accessToken,
            PaymentMethodSystemName: systemName,
        });

        if (result?.success) {
            return { token: result.token, accessToken };
        } else {
            throw new Error(Array.isArray(result?.error) ? result.error[0] : result?.error || DEFAULT_ERROR);
        }
    }
}
