import $ from 'jquery';

export default class HeaderController {
    static searchOpener() {
        return function openSearch() {
            const searchBar = $('#header_search_bar');

            if (searchBar?.hasClass('d-none')) {
                searchBar.removeClass('d-none');
                searchBar.addClass('open');
                $(
                    '#header_search_bar input.SearchBar__input, #header_search_bar input.ProductSearchBar__input'
                ).trigger('focus');
                searchBar.trigger('open.search');
            }
        };
    }

    static closeSearch() {
        const searchBar = $('#header_search_bar');

        if (searchBar?.hasClass('open')) {
            searchBar.removeClass('open');
            searchBar.addClass('d-none');
            searchBar.trigger('close.search');
        }
    }

    static init() {
        const nav = $('#main_nav');

        $('.header__navigation__search').on('click', (e) => {
            e.stopPropagation();
        });

        if (nav) {
            nav.on('shown.bs.collapse', () => {
                setTimeout(() => {
                    //using timeout to account for animation
                    document.body.style.position = 'fixed';
                    document.body.style.top = '-' + window.scrollY + 'px';
                }, 200);
            });

            nav.on('hide.bs.collapse', () => {
                const scrollY = document.body.style.top;

                document.body.style.position = '';
                document.body.style.top = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
            });
        }
    }
}
