/// <reference path="./Facet.js" />
import Facet from './Facet';

/**
 * Methods for manipulating lists of {Facet}'s.
 */
const FacetList = {
    /**
     * Finds a facet by attribute within a list of facets.
     *
     * @param {Facet[]} facets
     * @param {string} attribute
     * @returns Facet | null
     */
    get(facets, attribute) {
        const found = facets.filter((facet) => Facet.attribute(facet) === attribute);

        return found.length > 0 ? found[0] : null;
    },
    /**
     * Updates a facet by attribute within a list of facets.
     *
     * @param {Facet[]} facets
     * @param {string} attribute
     * @param {function (Facet): Facet} callback
     * @returns Facet[]
     */
    updateFacet(facets, attribute, callback) {
        return facets.map((facet) => (Facet.attribute(facet) === attribute ? callback(facet) : facet));
    },
    /**
     * Toggles a single facet value within a list of facets.
     *
     * @param {Facet[]} facets
     * @param {string} attribute
     * @param {string} value
     * @returns Facet[]
     */
    toggle(facets, attribute, value) {
        return FacetList.updateFacet(facets, attribute, (facet) =>
            Facet.updateValue(facet, value, (facetValue) => ({
                ...facetValue,
                Selected: !facetValue.Selected,
            }))
        );
    },
    /**
     * Deselects all values within a facet.
     *
     * @param {Facet[]} facets
     * @param {string} attribute
     * @returns Facet[]
     */
    deselectFacet(facets, attribute) {
        return FacetList.updateFacet(facets, attribute, Facet.deselectAllValues);
    },
    /**
     * Deselects all values within all facets.
     *
     * @param {Facet[]} facets
     * @returns Facet[]
     */
    deselectAll(facets) {
        return facets.map(Facet.deselectAllValues);
    },
    /**
     * Exclusively selects a single value within a facet.
     *
     * @param {Facet[]} facets
     * @param {string} attribute
     * @param {string} value
     * @returns Facet[]
     */
    exclusivelySelect(facets, attribute, value) {
        return FacetList.toggle(FacetList.deselectFacet(facets, attribute), attribute, value);
    },
    /**
     * Exclusively toggles a single value within a facet.
     *
     * @param {Facet[]} facets
     * @param {string} attribute
     * @param {string} value
     * @returns Facet[]
     */
    exclusivelyToggle(facets, attribute, value) {
        return FacetList.updateFacet(facets, attribute, (facet) =>
            Facet.mapValues(facet, (facetValue) => ({
                ...facetValue,
                Selected: facetValue.Value === value ? !facetValue.Selected : false,
            }))
        );
    },
};

export default FacetList;
