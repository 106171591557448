import { NODE_ENV } from '$env';

const logger = window?.console?.log ? console.log.bind(console) : () => {};

/**
 * Log debug statement to console -- these will be hidden in a production environment
 */
export function debug(...args) {
    if (NODE_ENV?.startsWith('dev')) {
        if (console.debug) console.debug(...args);
        else logger('[DEBUG]', ...args);
    }
}

/**
 * Log informational message to console
 */
export function info(...args) {
    if (console.info) console.info(...args);
    else logger('[INFO]', ...args);
}

/**
 * Log warning to console
 */
export function warn(msg, ...args) {
    if (NODE_ENV?.startsWith('dev')) {
        if (args.length) logger('[WARN DATA]', ...args);
        logger('[WARN STACK]', new Error().stack);
    }

    if (console.warn) console.warn(msg);
    else logger('[WARN]', msg);
}

/**
 * Log error to console -- throws if running in development environment
 */
export function error(msg, ...args) {
    if (NODE_ENV?.startsWith('dev')) {
        if (args.length) logger('[ERROR DATA]', ...args);
        logger('[ERROR STACK]', new Error().stack);
    }

    if (console.error) {
        console.error(msg);
    } else {
        logger('[ERROR]', msg);
    }

    if (NODE_ENV?.startsWith('dev')) {
        throw new Error(msg);
    }
}

/** Alias for debug **/
export const log = debug;

export default {
    log,
    debug,
    info,
    warn,
    error,
};
