import ReduxReducer from './ReduxReducer';

export default class ReduxFeature {
    static create({ initialState = null, reducers = {}, actions = {}, selectors = {}, defaultReducer } = {}) {
        return {
            actions,
            selectors,
            reducers,
            initialState,
            defaultReducer,
        };
    }

    static extend(feature = {}, { initialState, reducers, actions, selectors, defaultReducer } = {}) {
        let state = feature.initialState;

        if (initialState) {
            state =
                typeof initialState === 'object' && typeof feature.initialState === 'object'
                    ? { ...feature.initialState, ...initialState }
                    : initialState;
        }

        return {
            actions: { ...feature.actions, ...actions },
            selectors: { ...feature.selectors, ...selectors },
            reducers: { ...feature.reducers, ...reducers },
            initialState: state,
            defaultReducer: defaultReducer || feature.defaultReducer,
        };
    }

    static getReducer({ reducers, initialState, defaultReducer } = {}) {
        return ReduxReducer.create(reducers, initialState, defaultReducer);
    }
}
