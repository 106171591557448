import { PhoneNumberUtil } from 'google-libphonenumber';
import { ADDRESS } from 'Common/constants/fields';

const phoneUtil = PhoneNumberUtil.getInstance();

const DEDUPE = (m) => m[0];

const sortByDefault = ({ [ADDRESS.defaultAddress]: a }, { [ADDRESS.defaultAddress]: b }) => Number(b) - Number(a);

const sortByName = (
    { [ADDRESS.firstName]: a1, [ADDRESS.lastName]: a2 },
    { [ADDRESS.firstName]: b1, [ADDRESS.lastName]: b2 }
) => {
    const a = `${a1}${a2}`;
    const b = `${b1}${b2}`;

    if (a === b) return 0;
    return a < b ? -1 : 1;
};

export default class AddressUtil {
    static getPhoneValidationError(num) {
        if (!num) return 'Form.Error.Required';
        try {
            if (!phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(num)))
                return 'Commerce.Account.PhoneNumberValidationMessage';
        } catch (e) {
            return 'Commerce.Account.PhoneNumberValidationMessage';
        }
        return undefined;
    }

    static sortAddresses(a, b) {
        return sortByDefault(a, b) || sortByName(a, b);
    }

    static isValid(str) {
        return !this.getValidationError(str);
    }

    static getName({ [ADDRESS.firstName]: firstName = '', [ADDRESS.lastName]: lastName = '' } = {}) {
        return [firstName, lastName].filter(Boolean).join(' ');
    }

    static getContact({ [ADDRESS.email]: email, [ADDRESS.phone]: phone }) {
        return [email, phone].filter(Boolean).join('\n');
    }

    static getAddressee({ [ADDRESS.company]: company, ...rest }) {
        return [this.getName(rest), company, this.getContact(rest)].filter(Boolean).join('\n');
    }

    static matchesLocation(a, b) {
        return (
            a[ADDRESS.line1]?.toLowerCase() === b[ADDRESS.line1]?.toLowerCase() &&
            (a[ADDRESS.line2] || '').toLowerCase() === (b[ADDRESS.line2] || '').toLowerCase() &&
            a[ADDRESS.city]?.toLowerCase() === b[ADDRESS.city]?.toLowerCase() &&
            a[ADDRESS.zip] === b[ADDRESS.zip] &&
            a[ADDRESS.state]?.toLowerCase() === b[ADDRESS.state]?.toLowerCase() &&
            a[ADDRESS.country]?.toLowerCase() === b[ADDRESS.country]?.toLowerCase()
        );
    }

    static isValidAddressObject(
        {
            [ADDRESS.firstName]: firstName,
            [ADDRESS.lastName]: lastName,
            [ADDRESS.line1]: line1,
            [ADDRESS.city]: city,
            [ADDRESS.state]: state,
            [ADDRESS.zip]: zip,
            [ADDRESS.country]: country,
            [ADDRESS.email]: email,
            [ADDRESS.phone]: phone,
        } = {},
        isDigital = false
    ) {
        return Boolean(
            isDigital
                ? firstName && lastName && email
                : firstName && lastName && line1 && city && state && zip && country && phone
        );
    }

    static matchesPerson(a, b) {
        return this.getAddressee(a).toLowerCase() === this.getAddressee(b).toLowerCase();
    }

    static isEqual(a, b) {
        return (
            (a[ADDRESS.id] && a[ADDRESS.id] === b[ADDRESS.id]) ||
            (this.matchesPerson(a, b) && this.matchesLocation(a, b))
        );
    }

    static getValidationError(str) {
        if (!str) return 'Form.Error.Required';
        return null;
    }

    static cleanAddressString(str) {
        if (!str) return '';
        return str.replace(/[^a-z0-9.'\-\s#]/gi, '').replace(/(?:-+|\.+|'+|#+|\s+)/g, DEDUPE);
    }

    static cleanCityString(str) {
        if (!str) return '';
        return str.replace(/[^a-z0-9.'\-\s]/gi, '').replace(/(?:-+|\.+|'+|\s+)/g, DEDUPE);
    }

    static cleanPostalString(str) {
        if (!str) return '';
        return str.replace(/[^a-z0-9\-\s]/gi, '').replace(/(?:-+|\s+)/g, DEDUPE);
    }
}
