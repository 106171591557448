import '@babel/polyfill';
import formsValidation from './formsValidation';
import accordionScroll from './accordionScroll';
import exposeControllers from './exposeControllers';
import exposeUtils from './exposeUtils';

formsValidation();
accordionScroll();
exposeControllers();
exposeUtils();
