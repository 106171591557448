import { validation as VALIDATION_STRINGS } from 'Common/constants';

export default class Email {
    static isValid(value) {
        return !this.getValidationError(value);
    }

    static getValidationError(value) {
        if (!value) return VALIDATION_STRINGS.required;
        if (value.length < 6) return VALIDATION_STRINGS.tooShort;
        if (value.length > 255) return VALIDATION_STRINGS.tooLong;
        if (
            !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
                value
            )
        )
            return VALIDATION_STRINGS.invalidEmail;
        return undefined;
    }
}
