const itemScrollSize = 500;
const itemTopBuffer = 225;
const itemBottomBuffer = 275;
const yearFullShowBuffer = 175;
const popoutFullShowBuffer = 75;
const timelineImageHeight = 278; //this must also be updated in the SCSS!
const popoutWidth = 400; //this must also be updated in the SCSS!
const popoutLeftOffset = 100; //this must also be updated in the SCSS!
const backgroundScrollDilationFactor = 0.5;

export default class ScrollingTimelineController {
    static init(rootElement) {
        //show/hide logic for each year's icon and the popouts
        let popOutIndex = 0;

        rootElement.querySelectorAll('.timelinescroller__timeline li').forEach((element, yearIndex) => {
            const numPopouts = +element.dataset.length;
            const numPopoutSlots = Math.max(numPopouts, 1); //if there are no popouts, still give this item a vertical size of one popout

            const yearStartFadeInPosition = popOutIndex * itemScrollSize - itemTopBuffer;
            const yearStartFullShowPosition = popOutIndex * itemScrollSize - yearFullShowBuffer;
            const yearStartFadeOutPosition = (popOutIndex + numPopoutSlots - 1) * itemScrollSize + yearFullShowBuffer;
            const yearStartHidingPosition = (popOutIndex + numPopoutSlots - 1) * itemScrollSize + itemBottomBuffer;

            if (yearIndex == 0) {
                //first element should just be at the top and shown by default
                element.dataset['0'] = 'opacity: 1; top: 0px';
            } else {
                element.dataset[`${yearStartFadeInPosition - 1}`] = `opacity: 0;`; //hide immediately before we start the fade in
                element.dataset[`${yearStartFadeInPosition}`] = `opacity: 1; top: ${yearStartFadeInPosition}px`; //set opacity to 1 for the fade in since we do the actual fading on the image
                element.dataset[`${yearStartFullShowPosition}`] = `top: ${yearStartFullShowPosition}px`; //ensure that the vertical position remains corect
            }

            //set opacity on the image for the fade in/out
            element.querySelector('.timelinescroller__imagewrap img').dataset[`${yearStartFadeInPosition}`] =
                'opacity: 0'; //start the fade in for the image
            element.querySelector('.timelinescroller__imagewrap img').dataset[`${yearStartFullShowPosition}`] =
                'opacity: 1'; //end the fade in with fully showing the image
            element.querySelector('.timelinescroller__imagewrap img').dataset[`${yearStartFadeOutPosition}`] =
                'opacity: 1'; //start the fade out for the image
            element.querySelector('.timelinescroller__imagewrap img').dataset[`${yearStartHidingPosition}`] =
                'opacity: 0'; //end the fadeout with fully hiding the image

            element.dataset[`${yearStartFadeOutPosition}`] = `top: ${yearStartFadeOutPosition}px`; //start the fadeout
            element.dataset[`${yearStartHidingPosition}`] = `opacity: 1; top: ${yearStartHidingPosition}px`; //right before the fadeout ends (keep the outer element as opacity 1 since the fade is done on the image)
            element.dataset[`${yearStartHidingPosition + 1}`] = `opacity: 0;`; //hide after the fadeout point

            //for each popout for the year, have it scroll in/out of view
            element.querySelectorAll('.timelinescroller__popout').forEach((popoutElement, yearPopoutIndex) => {
                popoutElement.dataset[
                    `${(popOutIndex + yearPopoutIndex) * itemScrollSize - itemTopBuffer}`
                ] = `opacity: 0; left: -${popoutWidth - 50}px`;
                popoutElement.dataset[
                    `${(popOutIndex + yearPopoutIndex) * itemScrollSize - popoutFullShowBuffer}`
                ] = `opacity: 1; left: ${popoutLeftOffset + timelineImageHeight / 2}px`;
                popoutElement.dataset[
                    `${(popOutIndex + yearPopoutIndex) * itemScrollSize + popoutFullShowBuffer}`
                ] = `opacity: 1; left: ${popoutLeftOffset + timelineImageHeight / 2}px`;
                popoutElement.dataset[
                    `${(popOutIndex + yearPopoutIndex) * itemScrollSize + itemBottomBuffer}`
                ] = `opacity: 0; left: -${popoutWidth - 50}px`;
            });

            popOutIndex += numPopoutSlots;
        });

        //set the position of the ticks
        rootElement.querySelectorAll('.timelinescroller__ticks li').forEach((element, index) => {
            element.style.top = `${itemScrollSize * index + timelineImageHeight / 2}px`;
        });

        const totalHeight = popOutIndex * itemScrollSize;

        //also set the vertical scroll position of the image wrapper
        rootElement.querySelector('.timelinescroller__imagebackdrop').dataset['0'] = 'top: 0px';
        rootElement.querySelector('.timelinescroller__imagebackdrop').dataset[
            `${totalHeight}`
        ] = `top: ${totalHeight}px`;

        //set the height of everything
        rootElement.querySelector('.timelinescroller__inner').style.height = `${totalHeight}px`;
        rootElement.querySelector('.timelinescroller__timeline').style.height = `${totalHeight}px`;

        //set background scroll dilation
        rootElement.querySelector('.timelinescroller__inner').dataset['0'] = 'background-position: 0px 0px';
        rootElement.querySelector('.timelinescroller__inner').dataset[`${totalHeight}`] = `background-position: 0px ${
            totalHeight * backgroundScrollDilationFactor
        }px`;
    }
}
