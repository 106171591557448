import { useRef, useEffect } from 'react';
import px from 'prop-types';
import { useDispatch } from 'react-redux';
import { localization } from '~features';

export default function TranslationLoader({ strings }) {
    const dispatch = useDispatch();
    const stringsRef = useRef();

    useEffect(() => {
        if (strings && stringsRef.current !== strings) {
            stringsRef.current = strings;
            dispatch(localization.actions.updateStrings(strings));
        }
    }, [strings, dispatch]);

    return null;
}

TranslationLoader.propTypes = {
    strings: px.objectOf(px.string),
};
