import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { ReduxFeature } from 'Common/utils';
import * as features from './features';

const middleware = [thunk];

const reducer = combineReducers(
    Object.keys(features).reduce(
        (reducerMap, key) => ({
            ...reducerMap,
            [key]: ReduxFeature.getReducer(features[key]),
        }),
        {}
    )
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })
    : compose;

export default createStore(reducer, composeEnhancers(applyMiddleware(...middleware)));
