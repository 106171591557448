import { v4 } from 'uuid';
import $ from 'jquery';
import { Formatter, Cookie, GTM, Url } from 'Common/utils';
import { logger } from 'Common/core';
import { CART_LOCATION_HASH } from '~config/order';
import { setCustomerError, clearCustomerError } from '../customer/actions';
import { updateProductEntriesQuantity } from '../product/actions';
import * as types from './types';
import * as api from './api';
import state from './state';
import {
    getLineItemQtyByCode,
    getShipmentsByLineItems,
    getChanges,
    getRemoveCartGTMPayloadByCode,
    getRemoveCartFauxGTMPayloadByCode,
    getRemoveCartGTMPayload,
    getUpdateCartGTMPayload,
    isCartUpdating,
    getOrderConfig,
} from './selectors';
import { ADDRESS } from 'Common/constants/fields';

export function setState(nextState = state) {
    const payload = Object.keys(nextState).reduce(
        (obj, key) => ({ ...obj, [key]: nextState[key] === null ? state[key] : nextState[key] }),
        {}
    );

    return { type: types.SET_STATE, payload };
}

export function setCartUpdatingMutex(payload) {
    return { type: types.SET_IS_UPDATING, payload };
}

export function addValidationIssue(message) {
    return {
        type: types.ADD_VALIDATION_ISSUE,
        payload: message,
    };
}

export function clearValidationIssue() {
    return {
        type: types.CLEAR_VALIDATION_ISSUE,
    };
}

export function setUserMessage(
    message,
    success = true,
    type = null,
    position = null,
    translationParams = {},
    ts = Date.now()
) {
    return {
        type: types.SET_USER_MESSAGE,
        payload: { message, success, ts, type, position, translationParams },
    };
}

export function loadCart(cartApiOptions = {}) {
    return async (dispatch, getState) => {
        if (!isCartUpdating(getState())) {
            dispatch(setCartUpdatingMutex(true));
            const nextCart = await api.getCurrentCart(cartApiOptions);

            if (nextCart) dispatch(setState(nextCart));
            else dispatch(setCartUpdatingMutex(false));
        }
    };
}

export function clearChanges() {
    return { type: types.CLEAR_CHANGES };
}

export function clearCart(options = { expand: ['forms', 'ordertotals'] }) {
    return async (dispatch, getState) => {
        dispatch(setCartUpdatingMutex(true));

        const nextCart = await api.deleteCurrentCart(options);

        if (nextCart) {
            const gtmPayload = getRemoveCartGTMPayload(getState());

            if (gtmPayload) GTM.updateDataLayer(gtmPayload);
            window.location.reload();
        } else {
            dispatch(setCartUpdatingMutex(false));
        }
    };
}

export function stateUpdater(apiCallback, messageForError, onComplete, showGlobalError = true) {
    return async (dispatch, getState) => {
        let nextCart;

        try {
            dispatch(setCartUpdatingMutex(true));
            nextCart = await apiCallback(getState);

            if (nextCart) {
                dispatch(setState(nextCart));
            } else {
                dispatch(setCartUpdatingMutex(false));
            }
            if (onComplete) onComplete(nextCart?.ValidationIssues || [], nextCart);

            return true;
        } catch (e) {
            if (Array.isArray(e)) {
                const errors = e.map((error) => {
                    const issue = { Issue: error?.message || error };

                    if (showGlobalError) dispatch(addValidationIssue(issue));
                    return issue;
                });

                if (onComplete) onComplete(errors, null);
            } else {
                const issue = { Issue: messageForError || e?.message || e };

                if (showGlobalError) dispatch(addValidationIssue(issue));
                if (onComplete) onComplete([issue], null);
            }

            return false;
        }
    };
}

export function addItems(
    codeToQtyMap = {},
    {
        gtmPayload = null,
        rerouteLink = null,
        openCartOnSuccess: openCartOnSuccessOverride = null,
        showSuccessMessage: showSuccessMessageOverride = null,
        scrollToTop: scrollToTopOverride = null,
        statusCallback,
        cartApiOptions = { expand: ['forms', 'ordertotals'], update: ['promotions', 'lineitems'] },
    } = {}
) {
    return async (dispatch, getState) => {
        try {
            dispatch(setCartUpdatingMutex(true));
            dispatch(clearCustomerError());

            const totalQty = Object.values(codeToQtyMap).reduce((sum, qty) => sum + qty, 0);
            const codes = Object.keys(codeToQtyMap).filter((k) => Number(codeToQtyMap[k] ?? 0) > 0);
            const nextCart = await api.createCartLineItems(
                codes.map((code) => ({ code, quantity: Number(codeToQtyMap[code]) })),
                cartApiOptions
            );

            if (nextCart) {
                const issues = nextCart.ValidationIssues || [];
                const success = !issues?.find((i) => i.RefType === 'LineItem' && codes.includes(i.Ref));
                const config = getOrderConfig(getState());
                const openCartOnSuccess = openCartOnSuccessOverride ?? config.OPEN_CART_ON_ADD_ITEM;
                const showSuccessMessage = showSuccessMessageOverride ?? config.SUCCESS_NOTIFICATION_ON_ADD_ITEM;
                const scrollToTop = scrollToTopOverride ?? config.SCROLL_TOP_ON_ADD_ITEM;

                dispatch(setState(nextCart));
                dispatch(
                    updateProductEntriesQuantity(
                        codes.map((code) => ({ Code: code, Quantity: Number(codeToQtyMap[code]) }))
                    )
                );
                if (gtmPayload) GTM.updateDataLayer(gtmPayload);

                if (success) {
                    const message = `Commerce.Order.Cart.AddItem.SuccessMessage.${
                        totalQty > 1 ? 'Plural' : 'Singular'
                    }`;

                    if (showSuccessMessage) {
                        dispatch(setUserMessage(message, true, 'order', 'header', { qty: totalQty }));
                    }
                    if (rerouteLink) window.location.replace(rerouteLink);
                    else if (openCartOnSuccess) Url.current.addHash(CART_LOCATION_HASH).apply();
                    if (scrollToTop) $('html, body').animate({ scrollTop: 0 }, 'slow');
                    if (statusCallback) statusCallback(message, issues, { qty: totalQty });
                } else {
                    dispatch(setCartUpdatingMutex(false));
                    dispatch(setCustomerError(issues[0]?.Issue));
                    if (statusCallback) statusCallback(null, issues);
                }
            } else if (statusCallback) {
                dispatch(setCartUpdatingMutex(false));
                statusCallback(null);
            }
        } catch (e) {
            dispatch(setCartUpdatingMutex(false));
            dispatch(setCustomerError(e?.message ?? e));
            if (statusCallback) statusCallback(null);
        }
    };
}

export function addItem(
    itemCode,
    qty = 1,
    {
        gtmPayload = null,
        rerouteLink = null,
        openCartOnSuccess: openCartOnSuccessOverride = null,
        showSuccessMessage: showSuccessMessageOverride = null,
        scrollToTop: scrollToTopOverride = null,
        statusCallback,
        productRecommendationId = null,
        cartApiOptions = { expand: ['forms', 'ordertotals'], update: ['promotions', 'lineitems'] },
    } = {}
) {
    return async (dispatch, getState) => {
        try {
            dispatch(setCartUpdatingMutex(true));
            dispatch(clearCustomerError());

            const nextCart = await api.createCartLineItems(
                [{ code: itemCode, quantity: Number(qty), productRecommendationId }],
                cartApiOptions
            );

            if (nextCart) {
                const issues = nextCart?.ValidationIssues || [];
                const success = !issues?.find((i) => i.RefType === 'LineItem' && i.Ref === itemCode);
                const config = getOrderConfig(getState());
                const openCartOnSuccess = openCartOnSuccessOverride ?? config.OPEN_CART_ON_ADD_ITEM;
                const showSuccessMessage = showSuccessMessageOverride ?? config.SUCCESS_NOTIFICATION_ON_ADD_ITEM;
                const scrollToTop = scrollToTopOverride ?? config.SCROLL_TOP_ON_ADD_ITEM;

                dispatch(setState(nextCart));

                dispatch(updateProductEntriesQuantity([{ Code: itemCode, Quantity: qty }]));
                if (gtmPayload) GTM.updateDataLayer(gtmPayload);
                if (success) {
                    const message = `Commerce.Order.Cart.AddItem.SuccessMessage.${qty > 1 ? 'Plural' : 'Singular'}`;

                    if (showSuccessMessage) {
                        dispatch(setUserMessage(message, true, 'order', 'header', { qty }));
                    }
                    if (rerouteLink) window.location.replace(rerouteLink);
                    else if (openCartOnSuccess) Url.current.addHash(CART_LOCATION_HASH).apply();
                    if (scrollToTop) $('html, body').animate({ scrollTop: 0 }, 'slow');
                    if (statusCallback) statusCallback(message, issues, { qty });
                } else {
                    dispatch(setCustomerError(issues[0]?.Issue));
                    if (statusCallback) statusCallback(null, issues);
                }
            } else if (statusCallback) {
                dispatch(setCartUpdatingMutex(false));
                statusCallback();
            } else {
                dispatch(setCartUpdatingMutex(false));
            }
        } catch (e) {
            dispatch(setCartUpdatingMutex(false));
            dispatch(setCustomerError(e?.message ?? e));
            if (statusCallback) statusCallback();
        }
    };
}

export function removeItem({
    shipmentId,
    itemCode,
    messageForError,
    cartApiOptions = { expand: ['forms', 'ordertotals'] },
    faux = false,
}) {
    return async (dispatch, getState) => {
        let nextCart;
        const s = getState();
        const gtmPayload = faux
            ? getRemoveCartFauxGTMPayloadByCode(s, shipmentId, itemCode)
            : getRemoveCartGTMPayloadByCode(s, shipmentId, itemCode);

        try {
            dispatch(setCartUpdatingMutex(true));
            nextCart = await api.deleteCartLineItem(shipmentId, itemCode, cartApiOptions);
        } catch (e) {
            dispatch(addValidationIssue(messageForError || e?.message || e));
        }

        if (nextCart) {
            if (gtmPayload) GTM.updateDataLayer(gtmPayload);
            dispatch(setState(nextCart));
        } else {
            dispatch(setCartUpdatingMutex(false));
        }
    };
}

export function updateItem(
    shipmentId,
    itemCode,
    nextItemCode,
    quantity,
    cartApiOptions = { expand: ['forms', 'ordertotals'], update: ['promotions', 'lineitems'] }
) {
    return stateUpdater((getState) =>
        api.updateCartLineItem(
            shipmentId,
            itemCode,
            typeof quantity === 'number' ? quantity : getLineItemQtyByCode(getState(), shipmentId, itemCode),
            nextItemCode,
            cartApiOptions
        )
    );
}

export function updateCart(cartApiOptions = { expand: ['forms', 'ordertotals'] }) {
    return async (dispatch, getState) => {
        const changes = getChanges(getState());
        const qtyChanges = changes.filter((change) => change.type === 'qty');
        const addressChanges = changes.find((change) => change.type === 'address');

        dispatch(setCartUpdatingMutex(true));

        if (addressChanges) {
            const shipments = getShipmentsByLineItems(getState());

            const splitShipments = shipments.reduce((acc, shipment) => {
                const shipmentQuantity =
                    // we need to consolidate our quantity changes in the situation an item's quantity gets updated but not its address
                    qtyChanges?.find((qc) => qc.itemCode === shipment.code)?.qty ?? shipment.quantity;

                let match = acc.shipments.find((s) => s.lineItems.find((item) => item.Code === shipment.code));

                if (match) {
                    return acc;
                }
                match = acc.shipments.find((s) => s.address.Id === shipment.address.Id);
                if (match) {
                    match.lineItems = match.lineItems.concat([{ Code: shipment.code, Quantity: shipmentQuantity }]);
                    return acc;
                }
                acc.shipments = acc.shipments.concat([
                    {
                        address: shipment.address,
                        lineItems: [
                            {
                                Code: shipment.code,
                                Quantity: shipmentQuantity,
                            },
                        ],
                    },
                ]);
                return acc;
            }, addressChanges);

            const nextCart = await api.splitCart(splitShipments, cartApiOptions);

            if (nextCart) return dispatch(setState({ ...nextCart, changes: [] }));
        } else if (qtyChanges) {
            const nextCart = await api.updateCartQty(qtyChanges, cartApiOptions);

            if (nextCart) {
                const result = dispatch(setState({ ...nextCart, changes: [] }));

                dispatch(updateProductEntriesQuantity(qtyChanges.map((c) => ({ Code: c.itemCode, Quantity: c.qty }))));
                const gtmPayload = getUpdateCartGTMPayload(getState());

                if (gtmPayload) GTM.updateDataLayer(gtmPayload);

                return result;
            }
        }
    };
}

export function updateItemQty(shipmentId, itemCode, nextQty) {
    return (dispatch, getState) => {
        const originalQty = getLineItemQtyByCode(getState(), shipmentId, itemCode);

        return dispatch({
            type: types.UPDATE_ITEM_QTY,
            payload: { shipmentId, itemCode, nextQty, originalQty },
        });
    };
}

export function updateItemAddress(itemCode, quantity, address) {
    return {
        type: types.UPDATE_ITEM_ADDRESS,
        payload: { itemCode, quantity, address },
    };
}

export function addSavedAddress(form) {
    return {
        type: types.ADD_SAVE_ADDRESS,
        payload: {
            ...form,
            [ADDRESS.defaultAddress]: false,
            [ADDRESS.id]: (form && form[ADDRESS.id]) || v4(),
        },
    };
}

export function incItemQty(shipmentId, itemCode, mod = 1, cartApiOptions) {
    return (dispatch, getState) => {
        const qty = getLineItemQtyByCode(getState(), shipmentId, itemCode);

        dispatch(updateItemQty(shipmentId, itemCode, qty + mod, cartApiOptions));
    };
}

export function decItemQty(shipmentId, itemCode, mod = 1, cartApiOptions) {
    return incItemQty(shipmentId, itemCode, -mod, cartApiOptions);
}

export function updateShipment(shipmentForm, cartApiOptions = { expand: ['forms', 'ordertotals', 'shippingmethods'] }) {
    return stateUpdater(() => api.updateShipment(shipmentForm, cartApiOptions));
}

export function updateShipmentDetails(
    shipmentForm,
    cartApiOptions = {
        expand: ['forms', 'ordertotals', 'shippingmethods'],
        update: ['shipping', 'lineitems', 'promotions', 'payment'],
    }
) {
    return stateUpdater(() => api.updateShipmentDetail(shipmentForm, cartApiOptions));
}

export function fetchShippingMethods(shipmentId, countryCode, regionCode) {
    return async (dispatch) => {
        const methods = await api.getAvailableShippingMethods(shipmentId, countryCode, regionCode);

        if (methods?.length) {
            dispatch({ type: types.SET_SHIPPING_METHODS, payload: { methods, shipmentId } });
        } else {
            logger.warn(`Failed to fetch shipping methods: ${methods}`, {
                shipmentId,
                countryCode,
                regionCode,
            });
        }
    };
}

export function addPayment(
    paymentForm,
    reCaptchaToken,
    onComplete,
    cartApiOptions = { expand: ['forms', 'ordertotals'] },
    showGlobalError = true
) {
    return paymentForm
        ? stateUpdater(
              () => api.addPayment(paymentForm, reCaptchaToken, cartApiOptions),
              'Commerce.Order.Checkout.Payments.Card.ErrorInvalid.Label',
              onComplete,
              showGlobalError
          )
        : undefined;
}

export function removePayment(paymentMethodId, cardNumber, cartApiOptions = { expand: ['forms', 'ordertotals'] }) {
    return stateUpdater(() => api.removePayment(paymentMethodId, cardNumber, cartApiOptions));
}

export function updateOrderEmail(email, signup, cartApiOptions) {
    return stateUpdater(() => api.updateOrderEmail(email, signup, cartApiOptions));
}

export function saveCustomerAddress(
    address,
    cartApiOptions = { expand: ['forms', 'savedshippingaddresses', 'shippingmethods'] }
) {
    return stateUpdater(() => api.saveCustomerAddress(address, cartApiOptions));
}

export function addPromotion(
    code,
    cartApiOptions = { expand: ['forms', 'ordertotals'], update: ['shipping', 'lineitems', 'promotions', 'payment'] }
) {
    return async (dispatch) => {
        dispatch(setCartUpdatingMutex(true));

        const response = await api.createCartOrderPromotion(code, cartApiOptions);

        if (response) {
            const issue = response.ValidationIssues?.find((i) => i.RefType === 'Promotion' && i.Ref === code);

            if (issue) {
                dispatch(
                    addValidationIssue({
                        ...issue,
                        Issue: Formatter.property(issue.Issue, { code }),
                    })
                );
                setTimeout(() => dispatch(clearValidationIssue()), 5000);
            } else {
                dispatch(setState(response));
            }
        }
    };
}

export function removePromotion(code, cartApiOptions = { expand: ['forms', 'ordertotals'] }) {
    return async (dispatch) => {
        dispatch(setCartUpdatingMutex(true));

        const response = await api.deleteCartOrderPromotion(code, cartApiOptions);

        if (response) {
            dispatch(setState(response));
        }
    };
}

export function placeOrder(orderConfirmationPage, token, cartId, done) {
    return async (dispatch) => {
        try {
            dispatch(setCartUpdatingMutex(true));

            const response = await api.placeOrder(token, cartId);

            if (response?.Order) {
                dispatch(setCartUpdatingMutex(false));
                Cookie.saveCookie(response.Order.OrderId, `Order-Confirmation`);
                if (orderConfirmationPage) {
                    window.location.replace(`${orderConfirmationPage}?OrderId=${response.Order.OrderId}`);
                }
                if (done) done(null, response.Order);
            } else {
                const issue = (response?.ValidationIssues?.length && response.ValidationIssues[0]) || {
                    Issue: 'Commerce.Order.Checkout.OrderFailure.Label',
                };

                logger.warn(`Error placing order: ${issue.Issue}`, response);
                dispatch(addValidationIssue(issue));
                if (done) done(issue.Issue);
            }
        } catch (e) {
            logger.warn(`Error occurred while attempting to place order: ${e?.message || e}`, e);
            dispatch(addValidationIssue('Commerce.Order.Checkout.OrderFailure.Label'));
            if (done) done('Commerce.Order.Checkout.OrderFailure.Label');
        }
    };
}

export function orderUpdateQueue(updates, messageForError, onComplete) {
    if (!updates?.length) return;
    return async (dispatch) => {
        dispatch(
            stateUpdater(
                () =>
                    updates
                        .reduce(
                            (p, [apiFn, ...args]) =>
                                p.then(async (last) => {
                                    if (last === 'break') return 'break';
                                    if (last?.ValidationIssues?.length) {
                                        onComplete([last.ValidationIssues[0]]);
                                        return 'break';
                                    }
                                    return (await apiFn(...args)) || last;
                                }),
                            Promise.resolve()
                        )
                        .catch((e) => onComplete([e])),
                messageForError,
                onComplete
            )
        );
    };
}

export function getVerifiedAddresses(address) {
    return () => api.verifyAddress(address);
}

export function loadInPlaceAddToCartConfig(openCartOnAdd = false) {
    return {
        type: types.UPDATE_CONFIG,
        payload: {
            OPEN_CART_ON_ADD_ITEM: openCartOnAdd,
            SUCCESS_NOTIFICATION_ON_ADD_ITEM: false,
            SCROLL_TOP_ON_ADD_ITEM: false,
            USE_TOOLTIP_ON_ADD_ITEM_TO_WISH_LIST: true,
            SCROLL_TO_TOP: false,
        },
    };
}
