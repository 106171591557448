import * as types from './types';

export default {
    [types.SET_USER]: (state, user) => ({
        ...state,
        ...user,
    }),

    [types.SET_BILLING_ADDRESSES]: (state, billing = []) => ({
        ...state,
        billing,
    }),
    [types.ADD_BILLING_ADDRESS]: (state, address) => ({
        ...state,
        billing: state.billing.concat(address),
    }),
    [types.DEL_BILLING_ADDRESS]: (state, id) => ({
        ...state,
        billing: state.billing.filter((a) => a.id !== id),
    }),
    [types.UPDATE_BILLING_ADDRESS]: (state, address) => ({
        ...state,
        billing: state.billing.map((a) => (a.id === address.id ? { ...a, ...address } : a)),
    }),
    [types.SET_DEFAULT_BILLING]: (state, id) => ({
        ...state,
        billing: state.billing.map((a) => (a.id === id ? { ...a, default: true } : { ...a, default: false })),
    }),

    [types.SET_SHIPPING_ADDRESSES]: (state, shipping = []) => ({
        ...state,
        shipping,
    }),
    [types.ADD_SHIPPING_ADDRESS]: (state, address) => ({
        ...state,
        shipping: state.shipping.concat(address),
    }),
    [types.DEL_SHIPPING_ADDRESS]: (state, id) => ({
        ...state,
        shipping: state.shipping.filter((a) => a.id !== id),
    }),
    [types.UPDATE_SHIPPING_ADDRESS]: (state, address) => ({
        ...state,
        shipping: state.shipping.map((a) => (a.id === address.id ? { ...a, ...address } : a)),
    }),
    [types.SET_DEFAULT_SHIPPING]: (state, id) => ({
        ...state,
        shipping: state.shipping.map((a) => (a.id === id ? { ...a, default: true } : { ...a, default: false })),
    }),
};
