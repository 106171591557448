import $ from 'jquery';

export default class UserMenuController {
    static setup() {
        $('#userMenu').on('click', () => {
            $('.dropdown-menu-user').removeClass('d-none');
        });

        $(document).on('mouseup', (e) => {
            const container = $('.dropdown-menu-user');

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.addClass('d-none');
            }
        });
    }
}
