import { createSelector } from 'reselect';

function getAttribute(child, attr) {
    return child.Attributes.find((a) => a.AttributeName === attr);
}

export const getItem = (state) => state.product;

export const getItemDetails = createSelector(getItem, (product = {}) => product.details);

export const getSelectedCode = createSelector(getItem, (product = {}) => product.selectedCode);

export const getProductStore = createSelector(getItem, (product = {}) => product.byId);

export const getProductById = createSelector(
    (_, arg) => arg,
    getProductStore,
    (id, store = {}) => store[id]
);

export const getSelectedProduct = createSelector(
    getSelectedCode,
    getProductStore,
    (code, store = {}) => (code && store[code]) || undefined
);

export const getQuantity = createSelector(getItem, (product = {}) => product.quantity);

export const getRelatedProducts = createSelector(getItemDetails, (details = {}) => details.CatalogRelatedProducts);

export const getEpiRecommedationAlternativeProducts = createSelector(
    getItemDetails,
    (details = {}) => details.EpiRecommendationAlternativeProducts
);

export const getEpiRecommedationCrossSellProducts = createSelector(
    getItemDetails,
    (details = {}) => details.EpiRecommendationCrossSellProducts
);

export const getProductsByNode = createSelector(getItemDetails, (details = {}) => details.ProductsByNode);

export const getSpareParts = createSelector(getItemDetails, (details = {}) => details.SpareParts);

export const getRootSelectedChildren = createSelector(getItem, (product = {}) => product.selectedChildren);

export const getRootSelectedChild = createSelector(getItem, (product = {}) => product.selectedChild);

export const getProduct = createSelector(getItemDetails, (details = {}) => details.Product);

export const getProductUrl = createSelector(getProduct, (product = {}) => product?.ContentUrl || product?.ContentLink);

export const getFacets = createSelector(getProduct, (product = {}) => product.Facets);

// A mapping of each facet value and its stock status determined by a variant existing that is in stock and contains the facet/facet value in its variants.
export const getFacetValueStockStatuses = createSelector(getProduct, (product = {}) => {
    return product.Facets?.reduce((mapping, facet) => {
        return {
            ...mapping,
            [facet.Attribute]: facet.FacetValues.reduce((values, value) => {
                return {
                    ...values,
                    [value.Value]: product.Children.reduce((match, child) => {
                        if (match) return match;
                        return (
                            child.Attributes.find((a) => a.AttributeName === facet.Attribute)?.Values?.includes(
                                value.Value
                            ) && child.StockStatus === 'InStock'
                        );
                    }, false),
                };
            }, {}),
        };
    }, {});
});

export const getFeatures = createSelector(getProduct, (product = {}) => product.Features || undefined);

export const getOptions = createSelector(getProduct, (product = {}) => product.Options);

export const getSelectedAttributes = createSelector(getFacets, (facets = []) =>
    facets
        .map((f) => ({
            name: f.Attribute,
            values: f.FacetValues.map((v) => v.Selected && v.Value).filter(Boolean),
            controlType: f.SelectorControlType,
        }))
        .filter((a) => a.values.length)
);

export const getChildren = createSelector(getProduct, (product = {}) => product.Children);

export const getInStockChildren = createSelector(getProduct, (product = {}) =>
    product?.Children?.filter((a) => a.StockStatus === 'InStock')
);

export const getSelectedChild = createSelector(
    getRootSelectedChildren,
    getRootSelectedChild,
    getChildren,
    getProduct,
    (selectedChildren, selectedChild = {}, children = [], product = {}) => {
        if (product.Facets?.length > 0 && children?.length === 1) {
            return children[0];
        } else {
            return (selectedChildren || selectedChild) && children.find((c) => c.Code === selectedChild.Code);
        }
    }
);

// Current children are made up of variants that contain attribute values currently selected by the user.
export const getCurrentChildren = createSelector(
    getChildren,
    getSelectedAttributes,
    getSelectedChild,
    (children = [], attributes = [], selectedChild = null) => {
        const currentChildren = selectedChild
            ? [selectedChild]
            : children.filter((child) =>
                  attributes.reduce((match, { name, values }) => {
                      if (!match) return false;

                      const attribute = child.Attributes.find((a) => a.AttributeName === name);

                      return (
                          attribute?.Values &&
                          values.reduce((submatch, v) => submatch && attribute.Values.includes(v), true)
                      );
                  }, true)
              );

        return currentChildren;
    }
);

export const getValidFacetCombinations = createSelector(getProduct, getChildren, (product = {}, children = []) => {
    return children.map((c) => {
        return product.Facets.reduce((combination, f) => {
            const facetName = f.Attribute;
            const attribute = c.Attributes?.find((a) => a.AttributeName === facetName);
            const facetValue = attribute?.Values?.length ? attribute.Values[0] : null;

            return facetValue ? { ...combination, [facetName]: facetValue } : combination;
        }, {});
    });
});

export const getFilteredFacetCombinations = createSelector(
    getValidFacetCombinations,
    getSelectedAttributes,
    getFacets,
    (validCombinations = [], currentAttributes = [], facets = []) => {
        if (currentAttributes.length === 0 || facets.length === 1) return validCombinations;
        return validCombinations.filter((c) => {
            return currentAttributes.filter((a) => a.values.includes(c[a.name])).length === currentAttributes.length;
        });
    }
);

export const getDisabledFacetValues = createSelector(
    getChildren,
    getFilteredFacetCombinations,
    getFacets,
    getFacetValueStockStatuses,
    (children = [], filteredCombinations = [], facets = [], facetValueStockStatus = {}) =>
        facets.reduce(
            (obj, facet) => ({
                ...obj,
                [facet.Attribute]: children.reduce(
                    (match, child) => match || getAttribute(child, facet.Attribute),
                    false
                )
                    ? facet.FacetValues.reduce(
                          (valueObj, value) => ({
                              ...valueObj,
                              [value.Value]: {
                                  isDisabled:
                                      filteredCombinations.filter((c) => c[facet.Attribute] === value.Value).length ==
                                      0,
                                  hasStock: facetValueStockStatus[facet.Attribute][value.Value],
                                  isValid: !!children.find((c) =>
                                      c.Attributes.find((a) => a.AttributeName === facet.Attribute)?.Values.includes(
                                          value.Value
                                      )
                                  ),
                              },
                          }),
                          {}
                      )
                    : true,
            }),
            {}
        )
);

export const getCurrentFacets = createSelector(getFacets, getDisabledFacetValues, (facets = [], disabledMap = {}) =>
    facets.map((facet) => ({
        ...facet,
        FacetValues: facet.FacetValues?.filter((value) =>
            disabledMap[facet.Attribute] ? !disabledMap[facet.Attribute][value.Value] : false
        ),
    }))
);

export const getMedia = createSelector(
    getSelectedChild,
    getCurrentChildren,
    getSelectedAttributes,
    getProduct,
    (selected = null, children = [], attributes = [], product = {}) => {
        if (selected) {
            return selected?.GalleryMedia;
        }
        if (
            (product.Facets?.length > 0 && children?.length === 1) ||
            attributes?.find((a) => a.controlType.includes('color-'))
        ) {
            return children[0]?.GalleryMedia;
        }
        return product.GalleryMedia;
    }
);

export const getProductMedia = createSelector(getProduct, (product = {}) => {
    return product.GalleryMedia;
});

export const getProductDocuments = createSelector(getProduct, (product = {}) => {
    if (product.CatalogMedia?.length) {
        return product.CatalogMedia.filter((media) => media.MediaGroup === 'Document');
    }
});

export const getProductMainMedia = createSelector(
    getItem,
    getProduct,
    getProductMedia,
    (productState = {}, product = {}, currentMedia = []) => {
        if (productState.selectedMedia && currentMedia.find((m) => m.Url === productState.selectedMedia.Url)) {
            return productState.selectedMedia;
        }
        if (currentMedia.length) {
            return (
                currentMedia.find((media) => media.MediaGroup === 'Primary') ||
                currentMedia.find((media) => media.IsDefault) ||
                currentMedia.find((media) => media.Url === product.DefaultImageUrl) ||
                currentMedia[0]
            );
        }

        return product.GalleryMedia?.find((media) => media.Url === product.DefaultImageUrl) || null;
    }
);

export const getMainMedia = createSelector(
    getItem,
    getProduct,
    getMedia,
    (productState = {}, product = {}, currentMedia = []) => {
        if (productState.selectedMedia && currentMedia.find((m) => m.Url === productState.selectedMedia.Url)) {
            return productState.selectedMedia;
        }
        if (currentMedia.length) {
            return (
                currentMedia.find((media) => media.MediaGroup === 'Primary') ||
                currentMedia.find((media) => media.IsDefault) ||
                currentMedia.find((media) => media.Url === product.DefaultImageUrl) ||
                currentMedia[0]
            );
        }

        return product.GalleryMedia?.find((media) => media.Url === product.DefaultImageUrl) || null;
    }
);

export const getCurrentPriceInfo = createSelector(
    getSelectedChild,
    getCurrentChildren,
    getSelectedAttributes,
    getProduct,
    (selected = null, children = [], attributes = [], product = {}) => {
        if (selected) {
            return {
                price: selected.Price,
                useQtySalePrice:
                    selected.Price.QtySalePrices != null && Object.keys(selected.Price.QtySalePrices).length > 0,
            };
        }
        if (attributes.length && children.length === 1) {
            return {
                price: children[0].Price,
                useQtySalePrice:
                    children[0].Price.QtySalePrices != null && Object.keys(children[0].Price.QtySalePrices).length > 0,
            };
        }
        return { price: product.Price, useQtySalePrice: false };
    }
);

export const getChildByCode = createSelector(
    (_, code) => code,
    getChildren,
    (code, children) => children.find((c) => c.Code === code)
);

export const getChildTitle = createSelector(
    getSelectedChild,
    getCurrentChildren,
    getSelectedAttributes,
    getProduct,
    (selected = null, children = [], attributes = [], product = {}) => {
        if (selected) {
            return selected.DisplayName;
        }
        if (attributes.length && children.length && children.length === 1) {
            return children[0].DisplayName;
        }
        return product.DisplayName;
    }
);

export const getSingleActiveChild = createSelector(
    getSelectedChild,
    getCurrentChildren,
    getSelectedAttributes,
    (selected = null, children = [], attributes = []) => {
        if (selected) {
            return selected;
        }
        if (attributes.length && children.length && children.length === 1) {
            return children[0];
        }
        return null;
    }
);

export const getCurrentItemStatus = createSelector(
    getProduct,
    getSelectedChild,
    getCurrentChildren,
    getSelectedAttributes,
    (product = {}, selected = null, children = [], attributes = []) => {
        if (selected) {
            return {
                hasStock: selected.StockStatus === 'InStock',
                statusDisplayName: selected.StockStatusLabel,
                code: selected.Code,
            };
        }
        if (attributes.length && children.length === 1) {
            return {
                hasStock: children[0].StockStatus === 'InStock',
                statusDisplayName: children[0].StockStatusLabel,
                code: children[0].Code,
            };
        }

        return {
            hasStock: product.TypeId !== 'Product' && product.StockStatus === 'InStock',
            statusDisplayName: product.StockStatusLabel,
            code: product.Code,
        };
    }
);

export const getCurrentHazmat = createSelector(
    getSelectedChild,
    getCurrentChildren,
    getSelectedAttributes,
    getProduct,
    (selected = null, children = [], attributes = [], product = {}) => {
        if (selected) {
            return { code: selected.HazmatCode, description: selected.HazmatDescription };
        }
        if (attributes.length && children.length === 1) {
            return { code: children[0].HazmatCode, description: children[0].HazmatDescription };
        }

        return { code: product.HazmatCode, description: product.HazmatDescription };
    }
);

export const getCurrentMarketingBadges = createSelector(
    getSelectedChild,
    getCurrentChildren,
    getSelectedAttributes,
    getProduct,
    (selected = null, children = [], attributes = [], product = {}) => {
        if (selected) {
            return [...new Set(selected.MarketingBadges?.map((badge) => badge.toUpperCase()))];
        }

        if (attributes.length && children.length === 1) {
            return [...new Set(children[0].MarketingBadges?.map((badge) => badge.toUpperCase()))];
        }

        return [...new Set(product.MarketingBadges?.map((badge) => badge.toUpperCase()))];
    }
);

export const getCurrentOversize = createSelector(
    getSelectedChild,
    getCurrentChildren,
    getSelectedAttributes,
    getProduct,
    (selected = null, children = [], attributes = [], product = {}) => {
        if (selected) {
            return { class: selected.OversizeClass, description: selected.OversizeDescription };
        }
        if (attributes.length && children.length === 1) {
            return { code: children[0].OversizeClass, description: children[0].OversizeDescription };
        }

        return { code: product.OversizeClass, description: product.OversizeDescription };
    }
);

export const getProductDefinitions = createSelector(getChildren, (children = []) =>
    children.reduce(
        (acc, child) =>
            acc.concat(
                child.Attributes.filter(
                    (a) => a.IsSpecification && !acc.includes(a.AttributeDefinition) && a.AttributeDefinition !== ''
                ).map((a) => a.AttributeDefinition)
            ),
        []
    )
);
