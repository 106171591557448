export default class Cookie {
    static getCookieByName(cname) {
        const name = `${cname}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');

        for (let i = 0, l = ca.length; i < l; i++) {
            let c = ca[i];

            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }

            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }

        return '';
    }

    static createCookie(name, value, expireDate, path = '/', domain) {
        let expires = expireDate;
        let cookie = `${name}=${encodeURIComponent(value)};`;

        if (expires) {
            // If it's a date
            if (expires instanceof Date) {
                // If it isn't a valid date
                if (isNaN(expires.getTime())) {
                    expires = new Date();
                }
            } else {
                expires = new Date(new Date().getTime() + parseInt(expires) * 1000 * 60 * 60 * 24);
            }

            cookie += `expires=${expires.toGMTString()};`;
        }

        if (path) cookie += `path=${path};`;
        if (domain) cookie += `domain=${domain};`;

        document.cookie = cookie;
    }

    static saveCookie(cvalue, cname, expires, path, domain, overrideCookie = true) {
        const existingCookie = this.getCookieByName(cname);

        if (existingCookie && !overrideCookie) return;
        this.createCookie(cname, cvalue, expires, path, domain);
    }
}
