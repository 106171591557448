import pick from 'lodash.pick';
import { PAYMENT, ADDRESS, SHIPMENT } from 'Common/constants/fields';
import VALIDATION_STRINGS from 'Common/constants/validation';
import { logger } from 'Common/core';
import Spreedly from './Spreedly';
import Paymetric from './Paymetric';
import Formatter from './Formatter';
import Address from './AddressUtil';

const DEFAULT_SEPARATOR = ' ';
const DEFAULT_MASK = 'X';
const OTHER_MASKS = 'O\\*';
const MASK = new RegExp(`^[${DEFAULT_MASK}${OTHER_MASKS}]$`, 'i');
const NON_CARD_NUM = new RegExp(`[^0-9${DEFAULT_MASK}${OTHER_MASKS}]`, 'gi');

const AMEX_CVV = /^\d{4}$/;
const OTHER_CVV = /^\d{3}$/;
const ANY_CVV = /^\d{3,4}$/;

const PAYMENT_KEYS = Object.values(PAYMENT);
const ADDRESS_KEYS = Object.values(ADDRESS);
const FILTERED_PAYMENT_KEYS = PAYMENT_KEYS.filter((k) => ![PAYMENT.paymentId, PAYMENT.isDefault].includes(k));

export const ISSUER = {
    AMEX: 'american_express',
    VISA: 'visa',
    MASTER: 'master',
    DISCOVER: 'discover',
    DINER: 'diners_club',
    DINER_INT: 'diners_club_int',
    JCB: 'jcb',
    MAESTRO: 'maestro',
    GOOGLE: 'GooglePay',
    ANDROID: 'AndroidPay',
    APPLE: 'ApplePay',
    AMAZON: 'AmazonPay',
    PAYPAL: 'PayPal',
    CreditCard: 'CreditCard',
};

export const ICON_CLASS = {
    [ISSUER.AMEX]: 'fab fa-cc-amex',
    [ISSUER.VISA]: 'fab fa-cc-visa',
    [ISSUER.MASTER]: 'fab fa-cc-mastercard',
    [ISSUER.DISCOVER]: 'fab fa-cc-discover',
    [ISSUER.DINER]: 'fab fa-cc-diners-club',
    [ISSUER.DINER_INT]: 'fab fa-cc-diners-club',
    [ISSUER.JCB]: 'fab fa-cc-jcb',
    [ISSUER.MAESTRO]: 'fab fa-cc-mastercard',
    [ISSUER.GOOGLE]: 'icons icons-payment icon-google-pay',
    [ISSUER.APPLE]: 'fab fa-cc-apple-pay',
    [ISSUER.AMAZON]: 'fab fa-cc-amazon-pay',
    [ISSUER.PAYPAL]: 'fab fa-cc-paypal',
    [ISSUER.CreditCard]: 'fas fa-credit-card',
    [ISSUER.GOOGLE]: 'icons icons-payment icon-google-pay',
};

export const DEFAULT_AVAILABLE = [
    ISSUER.AMEX,
    ISSUER.VISA,
    ISSUER.MASTER,
    ISSUER.DISCOVER,
    ISSUER.DINER_INT,
    ISSUER.JCB,
    ISSUER.MAESTRO,
];

export const REGEX = {
    [ISSUER.DINER_INT]: /^(36|30|38|39|2014|2149)/,
    [ISSUER.DINER]: /^54/,
    [ISSUER.DISCOVER]: /^(6011|622|64|65)/,
    [ISSUER.MASTER]: /^(51|52|53|55|222[1-9]|2[3456]|27[01][0-9]|2720)/,
    [ISSUER.AMEX]: /^(34|37)/,
    [ISSUER.MAESTRO]: /^(5018|5020|5038|5893|6304|6759|6761|6762|6763|6759|676770|676774)/,
    [ISSUER.VISA]: /^4/,
    [ISSUER.JCB]: /^35/,
};

export const MINMAX = {
    [ISSUER.DINER_INT]: [14, 14],
    [ISSUER.DINER]: [16, 16],
    [ISSUER.DISCOVER]: [16, 16],
    [ISSUER.MASTER]: [16, 16],
    [ISSUER.AMEX]: [15, 15],
    [ISSUER.MAESTRO]: [12, 19],
    [ISSUER.VISA]: [16, 19],
    [ISSUER.JCB]: [16, 19],
};

export const SPACING = {
    [ISSUER.DINER_INT]: [4, 6],
    [ISSUER.DINER]: [4, 4, 4],
    [ISSUER.DISCOVER]: [4, 4, 4],
    [ISSUER.JCB]: [4, 4, 4],
    [ISSUER.MASTER]: [4, 4, 4],
    [ISSUER.MAESTRO]: [4, 4, 4, 4],
    [ISSUER.VISA]: [4, 4, 4, 4],
    [ISSUER.AMEX]: [4, 6],
};

export const VALID_CC_RE = /^(?:(?:36|30|38|39)[0-9]{12}|(?:2014|2149)[0-9]{10}|(?:54|55|64|65|51|52|53)[0-9]{14}|35[0-9]{14,17}|(?:601|22[234567]|622)[0-9]{13}|(?:34|37)[0-9]{13}|4[0-9]{15}|4[0-9]{17,18}|(?:5018|5020|5038|5893|6304|6759|6761|6762|6763|6759)[0-9]{8,15})$/;

const LOWER_TO_ISSUER = Object.values(ISSUER).reduce((o, val) => ({ ...o, [val.toLowerCase()]: val }), {});

export default class CreditCard {
    static get currentProcessor() {
        if (Paymetric.isReady()) return Paymetric;
        if (Spreedly.isReady()) return Spreedly;
        return null;
    }

    static ISSUER = ISSUER;

    static ISSUERS = Object.values(ISSUER);

    static ICON_CLASS = ICON_CLASS;

    static REGEX = REGEX;

    static DEFAULT_AVAILABLE = DEFAULT_AVAILABLE;

    static parseExp(date = '', def = [1, 1]) {
        const currentYear = new Date().getFullYear();
        const [month = '0', year = '00'] = date ? date.split('/') : def;

        return {
            month: Number(month),
            year: Number(
                year.length < 4
                    ? // you're welcome, 2095 folks
                      `${(currentYear % 100 >= 70 && Number(year) < 30 ? currentYear + 100 : currentYear)
                          .toString()
                          .slice(0, 2)}${year.slice(-2)}`
                    : year
            ),
        };
    }

    static maskNumber(number = '', { mask = DEFAULT_MASK, first = 0, last = 4 } = {}) {
        const str = number.replace(NON_CARD_NUM, '');

        if (!MASK.test(mask)) {
            logger.warn(`Provided CC mask '${mask}' is not UI-Safe. Add it to OTHER_MASKS constant.`);
        }
        return `${str.slice(0, first)}${str.slice(first, -last).replace(/./g, mask)}${str.slice(-last)}`;
    }

    static isEqual(a, b) {
        if (a === b) return true;
        if ((a === ISSUER.DINER || a === ISSUER.DINER_INT) && (b === ISSUER.DINER || b === ISSUER.DINER_INT))
            return true;
        return false;
    }

    static getCardNumberFormat({
        separator = DEFAULT_SEPARATOR,
        value = '',
        issuer = this.getIssuerByNumber(value),
    } = {}) {
        const spacing = SPACING[issuer] || [4, 4, 4, 4];

        return spacing
            .concat(Math.max(0, (MINMAX[issuer] ? MINMAX[issuer][1] : 16) - spacing.reduce((t, n) => t + n, 0)))
            .map((num) => Array(num).fill('#').join(''))
            .filter(Boolean)
            .join(separator);
    }

    static formatNumber(value = '', { separator = DEFAULT_SEPARATOR, issuer = this.getIssuerByNumber(value) } = {}) {
        const s = value.replace(NON_CARD_NUM, '');
        const spacing = SPACING[issuer] || [4, 4, 4, 4];
        let out = '';
        let i = 0;

        for (const n of spacing) {
            if (s.length > i) {
                out += `${i ? separator : ''}${s.slice(i, i + n)}`;
                i += n;
            } else {
                break;
            }
        }
        if (s.length > i) out += `${i ? separator : ''}${s.slice(i)}`;

        return out;
    }

    static getIcon(paymentType = ISSUER.CreditCard) {
        const issuer = LOWER_TO_ISSUER[paymentType?.toLowerCase()];

        return ICON_CLASS[issuer] || '';
    }

    static isValidNumber(ccNumber) {
        return ccNumber && VALID_CC_RE.test(ccNumber.replace(NON_CARD_NUM, ''));
    }

    static getIssuerByNumber(ccNumber = '') {
        const num = ccNumber?.replace(NON_CARD_NUM, '');

        return num
            ? Object.keys(REGEX).reduce((brand, key) => brand || (REGEX[key].test(num) ? key : null), null)
            : null;
    }

    static setupPaymentProcessor(options) {
        const processor = this.currentProcessor;

        if (!processor) throw new Error('No payment processor provided');
        return processor.setup ? this.currentProcessor.setup(options) : null;
    }

    static tearDownPaymentProcessor(options) {
        const processor = this.currentProcessor;

        if (!processor) throw new Error('No payment processor provided');
        return processor.tearDown ? this.currentProcessor.tearDown(options) : null;
    }

    static async tokenizePaymentForm(
        {
            [PAYMENT.token]: originalToken,
            [PAYMENT.cardDate]: date,
            [PAYMENT.expMonth]: expMonth,
            [PAYMENT.expYear]: expYear,
            [PAYMENT.cardNumber]: cardNumber,
            [PAYMENT.merchantGuid]: merchantGuid,
            [PAYMENT.systemName]: systemName,
            [PAYMENT.cardType]: creditCardType,
            [PAYMENT.cardCvv]: cardCvv,
            [PAYMENT.address]: {
                [ADDRESS.firstName]: firstName,
                [ADDRESS.lastName]: lastName,
                [ADDRESS.zip]: zip,
                [ADDRESS.phone]: phoneNumber,
                ...address
            },
            ...rest
        } = {},
        processor = this.currentProcessor
    ) {
        if (!processor) return { success: false, message: 'No payment processor provided' };
        try {
            const { month, year } = this.parseExp(date, [Number(expMonth), Number(expYear)]);
            const fullName = [firstName, lastName].filter((x) => x).join(' ');
            const { cardType = creditCardType, number = cardNumber, cvv = cardCvv, token } = originalToken
                ? { token: originalToken }
                : await processor.tokenizePayment({
                      fullName,
                      firstName,
                      lastName,
                      month,
                      year,
                      cardNumber,
                      phoneNumber,
                      systemName,
                      merchantGuid,
                      zip,
                      cardCvv,
                  });

            if (!token) throw '';

            return {
                success: true,
                creditCard: {
                    ...rest,
                    CustomerName: fullName,
                    [PAYMENT.systemName]: systemName,
                    [PAYMENT.cardType]: cardType,
                    [PAYMENT.cardNumber]: this.maskNumber(number),
                    [PAYMENT.cardCvv]: cvv,
                    [PAYMENT.expMonth]: month,
                    [PAYMENT.expYear]: year,
                    [PAYMENT.token]: token,
                    [PAYMENT.address]: {
                        ...address,
                        [ADDRESS.firstName]: firstName,
                        [ADDRESS.lastName]: lastName,
                        [ADDRESS.zip]: zip,
                        [ADDRESS.phone]: phoneNumber,
                    },
                },
            };
        } catch (e) {
            return {
                success: false,
                message: e?.length ? e[0].message : e?.message || e || 'Failed to add card',
            };
        }
    }

    static validateCVV(cvv, cardNumber) {
        const issuer = CreditCard.getIssuerByNumber(cardNumber);
        const pattern = issuer === ISSUER.AMEX ? AMEX_CVV : issuer ? OTHER_CVV : ANY_CVV;

        if (!pattern.test(cvv)) return VALIDATION_STRINGS.invalidCCVerification;
        return undefined;
    }

    static validateExpiration(month, year) {
        const now = new Date();
        const curYear = now.getFullYear();
        const curMonth = now.getMonth() + 1;

        if (month < 1 || month > 12) return VALIDATION_STRINGS.invalidCCDate;
        if (year > curYear + 50) return VALIDATION_STRINGS.invalidCCDate;
        if (year < curYear || (year === curYear && month < curMonth)) {
            return VALIDATION_STRINGS.expiredCCDate;
        }
        return undefined;
    }

    static isValidCVV(cvv, cardNumber) {
        return cvv && !CreditCard.validateCVV(cvv, cardNumber);
    }

    static isValidExpiration(month, year) {
        return month != null && year !== null && !CreditCard.validateExpiration(month, year);
    }

    static isValidPayment(payment) {
        return (
            payment &&
            (payment[PAYMENT.token] ||
                (CreditCard.isValidNumber(payment[PAYMENT.cardNumber]) &&
                    CreditCard.isValidExpiration(payment[PAYMENT.expMonth], payment[PAYMENT.expYear]) &&
                    CreditCard.isValidCVV(payment[PAYMENT.cardCvv], payment[PAYMENT.cardNumber]) &&
                    Address.isValidAddressObject(payment[PAYMENT.address])))
        );
    }

    static paymentToForm({
        payment,
        method = payment,
        dateFormat = 'MM / YY',
        formatter = Formatter.createMonthYearFormatter(dateFormat),
        savePayment = false,
        homogenize = false,
        defaultShipment = null,
    }) {
        // Default values with old properties to support deprecated payment objects
        const {
            [PAYMENT.paymentId]: id = payment.Id,
            [PAYMENT.expMonth]: m = payment.ExpirationMonth,
            [PAYMENT.expYear]: y = payment.ExpirationYear,
            [PAYMENT.cardType]: cardType = payment.CreditCardTypeFriendlyName,
            [PAYMENT.cardCvv]: cardCvv = payment.SecurityCode,
            [PAYMENT.cardNumber]: cardNumber = payment.CreditCardNumber ?? payment.GiftCardNumber,
            [PAYMENT.savePayment]: saved = savePayment,
            ...rest
        } = payment;

        const { [ADDRESS.phone]: phone = payment.PhoneNumber, ...address } = pick(
            payment[PAYMENT.address] ?? payment,
            ADDRESS_KEYS
        );

        let billingAddress = { ...address, [ADDRESS.phone]: phone };

        if (defaultShipment && !payment[PAYMENT.token] && !Address.isValidAddressObject(billingAddress)) {
            const shippingAddress = pick(defaultShipment[SHIPMENT.address], Object.values(ADDRESS));

            if (Address.isValidAddressObject(shippingAddress)) billingAddress = shippingAddress;
        }

        return {
            ...pick(rest, homogenize ? FILTERED_PAYMENT_KEYS : PAYMENT_KEYS),
            ...(homogenize ? null : { [PAYMENT.paymentId]: id }),
            [PAYMENT.cardNumber]: cardNumber,
            [PAYMENT.cardType]: cardType,
            [PAYMENT.cardCvv]: cardCvv,
            [PAYMENT.expMonth]: m ? Number(m) : 0,
            [PAYMENT.expYear]: y ? Number(y) : 0,
            [PAYMENT.cardDate]: y ? formatter.format(Number(m), Number(y)) : '',
            [PAYMENT.address]: billingAddress,
            [PAYMENT.systemName]: method[PAYMENT.systemName],
            [PAYMENT.paymentType]: method[PAYMENT.paymentType],
            [PAYMENT.displayName]: method[PAYMENT.displayName],
            [PAYMENT.customerName]:
                payment[PAYMENT.customerName] ||
                `${billingAddress[ADDRESS.firstName]} ${billingAddress[ADDRESS.lastName]}`,
            [PAYMENT.savePayment]: saved,
        };
    }
}
