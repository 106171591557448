import { Email, Password, ExtForm } from '../utils';
import { matchField } from './helpers';

export default class RegistrationController {
    static init(
        uid = 'RegistrationForm',
        {
            emailId = 'Email',
            confEmailId = 'ConfirmEmail',
            pwId = 'Password',
            confPwId = 'ConfirmPassword',
            fNameId = 'FirstName',
            lNameId = 'LastName',
            fieldErrorClass = 'invalid-feedback',
            apiErrorId = 'registration-errormessage',
            pwOnly = false,
            useRecaptcha = false,
            onError = null,
        } = {}
    ) {
        const fields = [
            { id: emailId, validator: pwOnly ? undefined : Email.getValidationError.bind(Email), hidden: pwOnly },
            {
                id: confEmailId,
                validator: pwOnly ? undefined : matchField(emailId, 'Form.Error.ConfirmEmail'),
                hidden: pwOnly,
            },
            { id: pwId, validator: Password.getMultiValidationError.bind(Password) },
            { id: confPwId, validator: matchField(pwId, 'Form.Error.ConfirmPassword', true) },
            { id: fNameId, hidden: pwOnly },
            { id: lNameId, hidden: pwOnly },
            useRecaptcha ? { id: 'ReCaptchaToken', hidden: true } : null,
        ].filter(Boolean);

        return ExtForm.initForm(
            uid,
            fields,
            '/AuthApi/RegisterAccount',
            {
                fieldErrorClass,
                apiErrorSelector: `#${apiErrorId}`,
                onError,
            },
            false
        );
    }
}
