import $ from 'jquery';
import { Url } from 'Common/utils';
import { getDeviceFingerprint, TOKEN_KEY } from 'Common/utils/Api';

export default class FingerPrintController {
    static async hookAnchorLinks(selector) {
        if (!selector) throw new Error(`No selector provided to FingerPrintController.hookAnchorLinks`);

        const { visitorId } = (await getDeviceFingerprint()) || {};

        if (visitorId) {
            $(selector).each((_, anchor) =>
                $(anchor).on('click', (e) => {
                    const href = Url.create(anchor.href).addParam(TOKEN_KEY, visitorId).href;

                    e.preventDefault();
                    if (anchor.target === '_blank' || e.metaKey || e.ctrlKey || e.shiftKey || e.altKey) {
                        window.open(href, '_blank');
                    } else {
                        window.location.href = href;
                    }
                })
            );
        }
    }
}
