import $ from 'jquery';
import { Viewport, onListItemHref } from 'Common/utils';

export default class NavigationController {
    static init({ originalActiveLink = -1, numLinks = 0 } = {}) {
        const ctrl = new this(originalActiveLink, numLinks);

        let lastMobile = ctrl.isMobile;

        $('#userMenu').dropdown();

        $('.navbar').on('click', (e) => {
            e.stopPropagation();
        });
        $('#btn-nav-open').on('click', ctrl.openNav);
        $('#btn-nav-close').on('click', ctrl.closeNav);

        for (let i = 0; i < ctrl.numLinks; i++) {
            if (!ctrl.isTouch) $(`#nav-item-${i}`).on('mouseover', ctrl.activator(i));
            $(`#nav-item-${i}`).on('click', ctrl.activator(i));
            $(`.mobile-nav-link[href]`).on('click', function makeActive() {
                $(this).addClass('active');
            });
            $(`#mobile-nav-item-${i}`).on('click', ctrl.activator(i));
        }

        $('.MenuCtaPromoBlock .link-list ul > li').on('click', onListItemHref);
        $('.nav-children > ul > li').on('click', onListItemHref);

        Viewport.onChange(() => {
            const { isMobile } = ctrl;

            if (isMobile !== lastMobile) {
                if (!isMobile) {
                    $('.nav-children').css('max-height', 'none');
                } else {
                    $('.nav-children').css('max-height', 0);
                    if (ctrl.activeLink >= 0) {
                        $(`#nav-list-${ctrl.activeLink}`).css(
                            'max-height',
                            Math.ceil($(`#nav-list-${ctrl.activeLink} ul`).outerHeight(true))
                        );
                    }
                }
                lastMobile = isMobile;
            }
        });
    }

    activeLink = -1;
    originalActiveLink = -1;

    get isTouch() {
        return Viewport.viewport.is.touch;
    }

    get isMobile() {
        return Viewport.viewport.is.mobile;
    }

    setActive(index) {
        if (index !== this.activeLink) {
            if (this.activeLink >= 0) {
                $(`#nav-item-${this.activeLink} > a`).removeClass('selected');
                $(`#navbar-cta-${this.activeLink}`).addClass('d-none');
                $(`#nav-list-${this.activeLink}`)
                    .removeClass('selected')
                    .css('max-height', this.isMobile ? 0 : 'none');
            }
            if (index >= 0) {
                const el = $(`#nav-list-${index}`);

                $(`#nav-item-${index} > a`).addClass('selected');
                $(`#navbar-cta-${index}`).removeClass('d-none');
                el.addClass('selected').css(
                    'max-height',
                    this.isMobile ? Math.ceil(el.find('ul').outerHeight(true)) : 'none'
                );
            }
        }
        this.activeLink = index;
    }

    constructor(originalActiveLink = -1, numLinks = 0) {
        this.originalActiveLink = originalActiveLink;
        this.numLinks = numLinks;
        this.setActive(originalActiveLink);
    }

    closeNav = () => {
        $(document).off('click', this.closeNav);
        $('#nav-container').removeClass('show').addClass('hide');
        $('#btn-nav-open').removeClass('hide');
        $('#nav-container').removeClass('enabled');
        setTimeout(() => {
            this.setActive(this.originalActiveLink);
            $('#nav-container').removeClass('enabled');
        }, 500);
    };

    openNav = () => {
        $('#nav-container').removeClass('hide').addClass('show');
        $('#btn-nav-open').addClass('hide');
        $(document).on('click', this.closeNav);
        setTimeout(() => $('#nav-container').addClass('enabled'), 400);
    };

    activator = (i) => () => this.setActive(i);
}
