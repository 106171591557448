function identity(state) {
    return state;
}

export const types = {
    RESET: '::RESET::',
};

export const actions = {
    reset: () => ({ type: types.RESET }),
};

export default class ReduxReducer {
    static types = types;

    static actions = actions;

    static create(reducerMap = {}, initialState = null, defaultReducer = identity, name = 'Reducer') {
        const reducer = (state = initialState, { type, payload } = {}) =>
            type === types.RESET ? initialState : (reducerMap[type] || defaultReducer)(state, payload);

        reducer.duckData = {
            reducerMap,
            initialState,
            defaultReducer,
        };
        Object.defineProperty(reducer, 'name', { value: name });

        return reducer;
    }
}
