export const EMAIL = {
    email: 'email',
    confirmEmail: 'confirmEmail',
    signup: 'signup',
};

export const MONOFORM = {
    customer: 'Customer',
    shipments: 'Shipments',
    payment: 'Payment',
};

export const ADDRESS = {
    id: 'Id',
    firstName: 'FirstName',
    lastName: 'LastName',
    email: 'Email',
    company: 'Organization',
    line1: 'Line1',
    line2: 'Line2',
    city: 'City',
    state: 'RegionCode',
    zip: 'PostalCode',
    country: 'CountryCode',
    phone: 'DaytimePhoneNumber',
    defaultAddress: 'IsDefault',
    isStreetAddress: 'IsStreetAddress',
};

export const GIFTCARDS = {
    cardNumber: 'giftCardNumber',
};

export const EMPTY_ADDRESS = {
    [ADDRESS.id]: null,
    [ADDRESS.firstName]: '',
    [ADDRESS.lastName]: '',
    [ADDRESS.email]: '',
    [ADDRESS.company]: '',
    [ADDRESS.line1]: '',
    [ADDRESS.line2]: '',
    [ADDRESS.city]: '',
    [ADDRESS.state]: null,
    [ADDRESS.country]: null,
    [ADDRESS.zip]: '',
    [ADDRESS.phone]: '',
    [ADDRESS.email]: '',
    [ADDRESS.defaultAddress]: false,
};

export const SHIPMENT = {
    isGift: 'IsGift',
    giftMessage: 'GiftMessage',
    shippingMethod: 'ShippingMethodName',
    shipmentId: 'ShipmentId',
    isDigital: 'IsDigitalShipment',
    shippingMethodDesc: 'ShippingMethodDescription',
    shippingMethodInfo: 'ShippingMethodDeliveryInfo',
    shippingMethodDisplay: 'ShippingMethodDisplayName',
    address: 'ShippingAddress',
};

export const PAYMENT = {
    paymentId: 'PaymentId',
    paymentMethodId: 'PaymentMethodId',
    merchantGuid: 'MerchantGuid',
    displayName: 'DisplayName',
    paymentType: 'PaymentType',
    systemName: 'SystemName',
    cardType: 'CardType',
    token: 'Token',
    cardNumber: 'CardNumber',
    cardCvv: 'CardSecurityCode',
    cardDate: 'ExpirationDate',
    savePayment: 'savePayment',
    expMonth: 'CardExpirationMonth',
    expYear: 'CardExpirationYear',
    extendedData: 'ExtendedPaymentData',
    isDefault: 'IsDefault',
    customerName: 'CustomerName',
    address: 'BillingAddress',
};

export const EMPTY_PAYMENT = {
    [PAYMENT.merchantGuid]: '',
    [PAYMENT.paymentType]: '',
    [PAYMENT.token]: '',
    [PAYMENT.systemName]: '',
    [PAYMENT.displayName]: '',
    [PAYMENT.cardType]: '',
    [PAYMENT.cardNumber]: '',
    [PAYMENT.cardCvv]: '',
    [PAYMENT.cardDate]: '',
    [PAYMENT.expMonth]: '',
    [PAYMENT.expYear]: '',
    [PAYMENT.savePayment]: false,
    [PAYMENT.address]: {
        [ADDRESS.firstName]: '',
        [ADDRESS.lastName]: '',
        [ADDRESS.company]: '',
        [ADDRESS.line1]: '',
        [ADDRESS.line2]: '',
        [ADDRESS.city]: '',
        [ADDRESS.state]: null,
        [ADDRESS.country]: null,
        [ADDRESS.zip]: '',
        [ADDRESS.phone]: '',
    },
};
