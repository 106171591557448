export const SET_CUSTOMER_ADDRESSES = 'customer:addresses:set';
export const DELETE_CUSTOMER_ADDRESS = 'customer:address:delete';

export const SET_CUSTOMER_GIFT_CARDS = 'customer:giftcards:set';
export const DELETE_CUSTOMER_GIFT_CARD = 'customer:giftcard:delete';
export const ADD_CUSTOMER_GIFT_CARD = 'customer:giftcard:add';

export const SET_CUSTOMER_PAYMENT_METHODS = 'customer:paymentmethods:set';
export const DELETE_CUSTOMER_PAYMENT_METHOD = 'customer:paymentmethod:delete';
export const ADD_CUSTOMER_PAYMENT_METHOD = 'customer:paymentmethod:add';
export const MARK_DEFAULT_CUSTOMER_PAYMENT_METHOD = 'customer:paymentmethod:markdefault';

export const SET_USER_EMAIL = 'customer:userEmail:set';

export const SET_CUSTOMER_MESSAGE = 'customer:customerMessage:set';
export const SET_CUSTOMER_ERROR = 'customer:customerError:set';

export const SET_CUSTOMER_ORDER_HISTORY = 'customer:orders:set';
export const SET_CUSTOMER_ORDER_RMARETURNDATA = 'customer:orders:rmaRequestSuccess:set';
export const SET_CUSTOMER_ORDERS = 'customerOrders:set';
export const CLEAR_CUSTOMER_ORDERS = 'customerOrders:clear';

// Notifications
export const SET_CUSTOMER_NOTIFICATION = 'customer:notifications:set';
export const SET_CUSTOMER_USER_NOTIFICATION_SUBSCRIPTIONS = 'customer:userNotificationSubscriptions:set';
export const REMOVE_CUSTOMER_USER_NOTIFICATION_SUBSCRIPTION = 'customer:userNotificationSubscriptions:remove';

// Mailchimp Subscription
export const SET_CUSTOMER_MAILCHIMP = 'customer:mailchimp:set';
