const DEDUPE = (m) => m[0];

export default class Name {
    static isValid(str) {
        return !this.getValidationError(str);
    }

    static getValidationError(str) {
        if (!str) return 'Form.Error.Required';
        if (this.hasNumber(str)) return 'Form.Error.NameNumber';
        if (this.hasSymbol(str)) return 'Form.Error.NameSymbol';
        return null;
    }

    static getMultiValidationError(str) {
        return [
            !str && 'Form.Error.Required',
            this.hasNumber(str) && 'Form.Error.NameNumber',
            this.hasSymbol(str) && 'Form.Error.NameSymbol',
        ].filter(Boolean);
    }

    static hasSymbol(str = '') {
        return /[^a-z0-9'.\-\s]/i.test(str);
    }

    static hasNumber(str = '') {
        return /[0-9]/.test(str);
    }

    static cleanString(str) {
        if (!str) return '';
        return str.replace(/[^a-z'.\-\s]/gi, '').replace(/(?:-+|\.+|'+|\s+)/g, DEDUPE);
    }
}
