import * as React from 'react';
import Player from 'react-player';
import px from 'prop-types';
import cx from 'classnames';
import { v4 } from 'uuid';
import { useLayoutEffect } from 'react';
import { youtubeThumbnailResolver } from 'Common/utils';

export const TYPES = {
    YouTube: {
        regex: /(youtube\.com|youtu\.be)/i,
        className: 'youtube',
    },
    Other: {
        className: 'other',
    },
};

export const CONFIG = {
    youtube: {
        iv_load_policy: 3,
        modestbranding: 1,
        loop: 1,
        rel: 0,
    },
};

export default function VideoPlayer({
    className,
    id: idProp = `Video-${v4()}`,
    showControls = true,
    url,
    previewImage,
    playing = false,
    playsInline = false,
    muted = false,
}) {
    const id = React.useRef(idProp);
    const typeClass = React.useMemo(
        () =>
            Object.values(TYPES).reduce(
                (cls, t) => cls || (t.regex ? t.regex.test(url) && t.className : t.className),
                ''
            ),
        [url]
    );

    const path = React.useMemo(() => (/^\/\//.test(url) ? `https:${url}` : url), [url]);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const onClickPreview = React.useCallback(() => {
        setIsPlaying(true);
    }, []);

    useLayoutEffect(() => {
        youtubeThumbnailResolver(`#${id.current} .react-player__preview`);
    }, [url]);

    React.useEffect(() => setIsPlaying(playing), [playing]);

    return (
        <div id={id.current} className={cx('VideoPlayer', typeClass, className)}>
            <Player
                className="VideoPlayer__player"
                url={path}
                light={previewImage ?? !playing}
                playing={isPlaying}
                playIcon={<i className="fas fa-play-circle" />}
                width="100%"
                height="100%"
                controls={showControls}
                onClickPreview={onClickPreview}
                config={CONFIG}
                playsInline={playsInline}
                muted={muted}
            />
        </div>
    );
}

VideoPlayer.propTypes = {
    className: px.string,
    showControls: px.bool,
    url: px.string,
    previewImage: px.string,
    playing: px.bool,
    id: px.string,
    playsInline: px.bool,
    muted: px.bool,
};
