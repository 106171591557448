import $ from 'jquery';
import getImageSource from './getImageSource';

/**
 * Note: sddefault youtube thumbnails are higher resolution than hqdefault,
 * which is extremely counter-intuitive.
 */

export default async function youtubeThumbnailResolver(selector, retries = 10) {
    const result = $(selector);

    if (result.length && result[0].style?.backgroundImage) {
        return Promise.all(Array.from(result).map(resolveThumbnail));
    } else if (retries > 0) {
        return new Promise((res) => setTimeout(() => res(youtubeThumbnailResolver(selector, retries - 1)), 100));
    }
}

async function resolveThumbnail(el) {
    if (/\/(hq|sd)?default/i.test(el.style.backgroundImage || '')) {
        const originalImageUrl = el.style.backgroundImage.replace(/(^\s*url\(["'])|(["']\)\s*$)/g, '');
        const tryUrls = [
            originalImageUrl.replace(/(hq|sd)?default/gi, 'maxresdefault'),
            originalImageUrl.replace(/(hq|sd)?default/gi, 'hqdefault'),
            originalImageUrl.replace(/(hq|sd)?default/gi, 'sddefault'),
            originalImageUrl.replace(/(hq|sd)?default/gi, 'default'),
        ];

        const src = await getImageSource(tryUrls, originalImageUrl, { minWidth: 480 });

        el.style.backgroundImage = `url("${src}")`;
    }
}
