import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import px from 'prop-types';
import debounce from 'lodash.debounce';
import { Icon } from 'Common/components/ui';
import { useTranslation } from 'Common/hooks';

/**
 * Scrolls to the top of the page.
 */
function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
}

/**
 * Floating action button that scrolls to the top of the page when clicked.
 *
 * @param {Props} Properties
 * @returns {Element}
 */
export default function ScrollToTopFab({ className, style }) {
    const [isVisible, setIsVisible] = useState(window.scrollY > 0);
    const btnLbl = useTranslation('ScrollToTop.Button.Label');

    useEffect(() => {
        function handleScroll() {
            setIsVisible(window.scrollY > 0);
        }

        window.addEventListener('scroll', debounce(handleScroll, 200));

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <button
            type="button"
            onClick={scrollToTop}
            className={cx('ScrollToTopFab', !isVisible && 'ScrollToTopFab--hidden', className)}
            style={style}
            aria-label={btnLbl}
        >
            <Icon className="fas fa-arrow-up" />
        </button>
    );
}

ScrollToTopFab.propTypes = {
    className: px.string,
    style: px.object,
};
