export function isCartValidationIssue(issue) {
    return (
        !!issue &&
        issue.IssueName !== 'PromotionNotApplied' &&
        issue.RefType !== 'LineItem' &&
        issue.RefType !== 'Payment'
    );
}

export function isLineItemValidationIssue(issue) {
    return !!issue && issue.IssueName !== 'PromotionNotApplied' && issue.RefType === 'LineItem';
}

export function isNonLineItemValidationIssue(issue) {
    return !!issue && issue.IssueName !== 'PromotionNotApplied' && issue.RefType !== 'LineItem';
}

export function isNonPaymentValidationIssue(issue) {
    return !!issue && issue.IssueName !== 'PromotionNotApplied' && issue.RefType !== 'Payment';
}

export function isPromoCodeValidationIssue(issue) {
    return !!issue && issue.IssueName === 'PromotionNotApplied'; 
}
