export const TAGS = {
    PLP: 'PLP Quickview',
    PDP: 'PDP',
    PRO_QUICK: 'Pro Quickview',
    CONTENT_QUICK: 'Content Quickview',
    RECOMMENDED_QUICK: 'Recommended Products',
    RELATED: 'Related Products',
    SEARCH_QUICK: 'Search Results',
    CART: 'Cart',
    FOOTER: 'Footer',
};

export const LAYERS = {
    ECOMMERCE: 'ecommerce',
    EMAILSIGNUP: 'emailsignup',
};

export const CHECKOUT_STEPS = {
    CUSTOMER: 'begin_checkout',
    SHIPPING: 'add_shipping_info',
    PAYMENT: 'add_payment_info',
};
