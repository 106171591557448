import fp from '@fingerprintjs/fingerprintjs-pro';
import fpFree from '@fingerprintjs/fingerprintjs';
import { v4 } from 'uuid';
import $ from 'jquery';
import isbot from 'isbot';
import { logger } from 'Common/core';
import Url from './Url';
import Cookie from './Cookie';

export const TOKEN_KEY = 'fp-token';
export const TOKEN_TIMEOUT_MS = 3 * 24 * 3600 * 1000; // 3 Days
export const ANTIFORGERY_ELEMENT_NAME = '__RequestVerificationToken';
export const FINGERPRINT_ELEMENT_NAME = '__FingerprintApiKey';
export const ENDPOINT_ELEMENT_NAME = '__FingerprintEndpoint';
let fingerprint = undefined;
let fingerprintLoad = false;

export function getAntiForgeryToken() {
    const elements = document.getElementsByName(ANTIFORGERY_ELEMENT_NAME);

    return elements?.length ? elements[0].value.replace(/^:/, '') : undefined;
}

export function getFingerprintApiKey() {
    const elements = document.getElementsByName(FINGERPRINT_ELEMENT_NAME);

    return elements?.length ? elements[0].value : undefined;
}

export function getFingerprintEndpoint() {
    const elements = document.getElementsByName(ENDPOINT_ELEMENT_NAME);

    return elements?.length ? elements[0].value : undefined;
}

export function clearFingerprint() {
    window.localStorage?.removeItem(TOKEN_KEY);
    window.sessionStorage?.removeItem(TOKEN_KEY);
}

export function getFingerprintOverride() {
    const url = Url.current;

    if (url.hasParam(TOKEN_KEY)) {
        const token = { visitorId: url.consumeParam(TOKEN_KEY), method: 'override' };

        if (token.visitorId) {
            saveFingerprint(token);
            url.apply();
            return token;
        }
    }

    return undefined;
}

export function loadFingerprint() {
    try {
        const json = window.localStorage?.getItem(TOKEN_KEY) || window.sessionStorage?.getItem(TOKEN_KEY) || undefined;

        if (json) {
            const { ts = 0, ...token } = JSON.parse(json);

            if (Date.now() <= ts + TOKEN_TIMEOUT_MS) {
                token.cached = ts;
                return token;
            }
        }

        if (json) clearFingerprint();
    } catch (e) {
        // do nothing
    }

    return undefined;
}

export function saveFingerprint(token) {
    const json = JSON.stringify({ ...token, ts: Date.now() });

    return window.localStorage?.setItem
        ? window.localStorage.setItem(TOKEN_KEY, json)
        : window.sessionStorage?.setItem
        ? window.sessionStorage.setItem(TOKEN_KEY, json)
        : undefined;
}

export async function calcFingerprint() {
    if (!fingerprintLoad) {
        fingerprintLoad = true;
        fingerprint = getFingerprintOverride() || loadFingerprint();

        if (!fingerprint) {
            if (isbot(window.navigator.userAgent)) {
                fingerprint = { visitorId: v4().replace(/-/g, ''), method: 'bot' };
            } else {
                const apiKey = getFingerprintApiKey();
                const endpoint = getFingerprintEndpoint() || undefined;
                let fpPromise;

                try {
                    if (!apiKey) throw new Error('no key');
                    fpPromise = await fp.load({ apiKey, endpoint, monitoring: false });
                } catch (e) {
                    logger.warn('Could not use precise fingerprint api', e);
                }

                if (!fpPromise) {
                    try {
                        fpPromise = await fpFree.load({ monitoring: false });
                    } catch (e) {
                        logger.warn('Could not use public fingerprint api', e);
                    }
                }

                if (fpPromise) {
                    try {
                        fingerprint = await fpPromise.get();
                        fingerprint.method = 'fpjs';
                    } catch (e) {
                        // nothing
                    }
                }

                if (!fingerprint) {
                    fingerprint = { visitorId: v4().replace(/-/g, ''), method: 'uuid' };
                }
            }
        }

        saveFingerprint(fingerprint);
        return fingerprint;
    } else if (fingerprint) {
        return fingerprint;
    } else {
        return new Promise((res) => {
            const start = Date.now();
            const handle = setInterval(() => {
                if (fingerprint) {
                    clearInterval(handle);
                    res(fingerprint);
                } else if (Date.now() > start + 30000) {
                    clearInterval(handle);
                    logger.warn(`Timed out waiting for fingerprint calculation.`);
                    res(fingerprint);
                }
            }, 100);
        });
    }
}

export async function getDeviceFingerprint() {
    if (!fingerprint) fingerprint = await calcFingerprint();

    return fingerprint?.method === 'bot' ? undefined : fingerprint;
}

export class HTTPError extends Error {
    constructor(message, code = 500, data = {}) {
        super(message);
        this.code = code;
        this.data = data;
    }
}

export default class Api {
    static HTTPError = HTTPError;

    static get antiForgeryToken() {
        return getAntiForgeryToken();
    }

    static async fetch(
        endpoint,
        {
            method = 'GET',
            query = null,
            data = null,
            safe = true,
            cache = true,
            antiForgery = true,
            useFingerprint = true,
            headers: requestHeaders = {},
            credentials = 'include',
            mode = 'cors',
            noApi = false,
            allowText = false,
        }
    ) {
        const headers = { ...requestHeaders };
        let body = data;
        let url =
            noApi || /^(https?:\/\/|\/\/)/i.test(endpoint)
                ? endpoint
                : `/api${endpoint[0] === '/' ? '' : '/'}${endpoint}`;

        if (antiForgery) {
            headers['RequestVerificationToken'] = getAntiForgeryToken();
        }
        if (useFingerprint) {
            const fingerprint = (await getDeviceFingerprint())?.visitorId;
            headers['DeviceFingerPrint'] = fingerprint;
            Cookie.createCookie(`rather.df`, fingerprint);
        }

        if (data && typeof data !== 'string') {
            if (data instanceof FormData) {
                body = data;
            } else {
                body = JSON.stringify(data);
                headers['Content-Type'] = 'application/json';
            }
        }

        if (query) {
            const qstr =
                query instanceof URLSearchParams
                    ? query.toString()
                    : typeof query === 'string'
                    ? query
                    : typeof query === 'object'
                    ? Object.keys(query)
                          .map((k) =>
                              Array.isArray(query[k])
                                  ? query[k].map((e) => `${k}=${encodeURIComponent(e)}`).join('&')
                                  : `${k}=${encodeURIComponent(query[k])}`
                          )
                          .join('&')
                    : '';

            if (qstr) url = `${url}?${qstr}`;
        }

        try {
            const response = await fetch(url, {
                credentials,
                cache: cache ? 'default' : 'no-store',
                mode,
                headers,
                body,
                method,
            });

            const contentType = response.headers.get('Content-Type') || 'text/html';
            const code = Number(response.status || 0);

            if (code >= 200 && code < 400) {
                if (contentType.includes('json')) {
                    return response.json();
                } else if (allowText) {
                    return response.text();
                }
            } else if (contentType.includes('json')) {
                const { Message, errors, ...rest } = (await response.json()) || {};
                const message = Message || (errors?.length && errors[0]) || '';

                throw new HTTPError(message?.message || message, code, rest);
            } else {
                const text = (await response.text())?.trim();

                if (text[0] !== '<') {
                    throw new HTTPError(text, code);
                }
            }
            throw new HTTPError(`Unexpected response from ${endpoint}`);
        } catch (e) {
            logger.warn(`API call to ${endpoint} failed.`, e);

            if (safe) return null;
            if (e?.code) throw e;
            throw new HTTPError(`API call to ${endpoint} failed.`, 500, { error: e });
        }
    }

    static GET(
        endpoint,
        params,
        { safe, headers, antiForgery, noApi, allowText, mode, credentials, useFingerprint, cache = true } = {}
    ) {
        return Api.fetch(endpoint, {
            method: 'GET',
            query: params,
            safe,
            headers,
            antiForgery,
            noApi,
            allowText,
            mode,
            credentials,
            useFingerprint,
            cache,
        });
    }

    static DELETE(
        endpoint,
        params,
        { safe, headers, antiForgery, noApi, allowText, data, mode, credentials, useFingerprint, cache = false } = {}
    ) {
        return Api.fetch(endpoint, {
            method: 'DELETE',
            query: params,
            safe,
            data,
            headers,
            antiForgery,
            noApi,
            allowText,
            cache,
            mode,
            credentials,
            useFingerprint,
        });
    }

    static POST(
        endpoint,
        data,
        { params, safe, headers, antiForgery, noApi, allowText, cache = false, mode, credentials, useFingerprint } = {}
    ) {
        return Api.fetch(endpoint, {
            method: 'POST',
            data,
            query: params,
            safe,
            headers,
            antiForgery,
            noApi,
            allowText,
            cache,
            mode,
            credentials,
            useFingerprint,
        });
    }

    static PUT(
        endpoint,
        data,
        { params, safe, headers, antiForgery, cache = false, noApi, allowText, mode, credentials, useFingerprint } = {}
    ) {
        return Api.fetch(endpoint, {
            method: 'PUT',
            data,
            query: params,
            safe,
            headers,
            antiForgery,
            noApi,
            allowText,
            cache,
            mode,
            credentials,
            useFingerprint,
        });
    }

    static async submitForm(path, formSelector) {
        const data = new URLSearchParams($(formSelector).serialize());
        let response;

        if (!data.get(ANTIFORGERY_ELEMENT_NAME)) data.append(ANTIFORGERY_ELEMENT_NAME, getAntiForgeryToken());
        $(`${formSelector} input:not(:disabled)`).data('form-disable', true).prop('disabled', true);
        response = await this.POST(path, data.toString(), {
            noApi: true,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        $(`${formSelector} input[form-disable=true]:disabled`).data('form-disable', false).prop('disabled', false);
        return response;
    }
}

window.clearFingerprint = clearFingerprint;
