import $ from 'jquery';
import { logger } from 'Common/core';
import Api from './Api';

function required(value) {
    if (!value) return 'Form.Error.Required';
}

function noop() {
    return undefined;
}

export default class ExtForm {
    static showErrors(inputSelector, errorSelector, messages) {
        const msgs = (Array.isArray(messages) ? messages : [messages]).filter(Boolean);
        const error = $(errorSelector);
        const input = $(inputSelector);

        error.find('span.d-block').removeClass('d-block').addClass('d-none');
        if (msgs.length) {
            input.addClass('invalid');
            error.removeClass('d-none');
            msgs.forEach((message) => {
                error.find(`span[data-error="${message}"]`).removeClass('d-none').addClass('d-block');
            });
        } else {
            error.addClass('d-none');
            input.removeClass('invalid');
        }
    }

    static createValidator(state, validators, btn, { fieldErrorClass, buttonAlwaysEnabled = false }) {
        return () => {
            const formIsValid = Object.keys(state).reduce((f, field) => {
                const message = validators[field]($(`#${field}`).val());

                if (state[field].touched) this.showErrors(`#${field}`, `#${field} + .${fieldErrorClass}`, message);
                state[field].valid = !message?.length;
                return f && state[field].valid;
            }, true);

            if (!buttonAlwaysEnabled && formIsValid) btn.removeAttr('disabled');
            else if (!buttonAlwaysEnabled) btn.attr('disabled', 'disabled');
            return formIsValid;
        };
    }

    static initForm(
        uid,
        allFields,
        apiEndpoint,
        {
            fieldErrorClass = 'invalid-feedback',
            apiErrorSelector = '#api-errormessage',
            onComplete,
            onError,
            buttonAlwaysEnabled = false,
            scrollToTop = true,
        } = {},
        clearFields = true
    ) {
        const form = $(`#${uid}`);
        const btn = $(`#${uid} [type="submit"]`);
        const apiError = $(apiErrorSelector);
        const fields = allFields.filter((field) => !field.hidden);
        const state = allFields.reduce((s, f) => ({ ...s, [f.id]: { touched: false, valid: false } }), {});
        const validators = allFields.reduce((v, f) => {
            if (document.getElementById(f.id)) {
                return {
                    ...v,
                    [f.id]:
                        (typeof f.validator === 'function' && f.validator) ||
                        ($(`#${f.id}`).attr('required') ? required : noop),
                };
            } else {
                logger.debug(`No field found for id: ${f.id}`);
                return v;
            }
        }, {});

        const validator = this.createValidator(state, validators, btn, { fieldErrorClass, buttonAlwaysEnabled });

        const handleErrors = (errors) => {
            if (errors) {
                errors.forEach((msg) => apiError.append(`<span class="d-block">${msg}</span>`));
                apiError.removeClass('d-none');
            }
        };

        const disableForm = () => {
            fields.forEach((field) => {
                $(`#${field.id}`).attr('disabled', 'disabled');
            });
            btn.attr('disabled', 'disabled');
        };

        const enableForm = () => {
            fields.forEach((field) => {
                $(`#${field.id}`).removeAttr('disabled');
            });
            btn.removeAttr('disabled');
        };

        const initForm = (clearValues = true, setup = false) => {
            fields.forEach((field) => {
                const el = $(`#${field.id}`);
                const touch = () => {
                    state[field.id].touched = true;
                    el.off('input', touch);
                    el.off('blur', touch);
                };

                state[field.id].touched = false;
                state[field.id].valid = false;
                if (clearValues) el.val('');
                el.on('input', touch);
                el.on('blur', touch);
                if (setup) {
                    el.on('input', validator);
                    el.on('blur', validator);
                }
            });
            validator();
        };

        form.on('submit', (event) => {
            event.preventDefault();
            if (validator()) {
                const formData = form.serialize();

                apiError.html('');
                apiError.removeClass('alert-danger alert-success');
                apiError.addClass('d-none alert-danger');
                disableForm();
                Api.POST(apiEndpoint, formData, {
                    safe: false,
                    noApi: true,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                })
                    .then(({ success, returnUrl, errors, message }) => {
                        if (success) {
                            if (scrollToTop) {
                                window.scrollTo(0, 0);
                            }
                            if (onComplete) onComplete({ message, enableForm, initForm, returnUrl });
                            else if (returnUrl) window.location.replace(decodeURIComponent(returnUrl));
                            else window.location.reload();
                        } else {
                            handleErrors(errors || (message ? [message] : null));
                            if (onError) onError();
                            enableForm();
                        }
                    })
                    .catch((e) => {
                        handleErrors(e?.data?.errors || [e?.message || e]);
                        if (onError) onError();
                        enableForm();
                    });
            }
        });

        initForm(clearFields, true);

        return validator;
    }
}
