export default function setupFormsValidation() {
    window.addEventListener(
        'load',
        () => {
            // Fetch all the forms we want to apply custom Bootstrap validation styles to
            const forms = Array.from(document.getElementsByClassName('needs-validation'));

            // Loop over them and prevent submission
            forms.forEach((form) => {
                form.addEventListener(
                    'submit',
                    (event) => {
                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                        }
                        form.classList.add('was-validated');
                    },
                    false
                );
            });
        },
        false
    );
}
