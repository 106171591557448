import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Formatter } from 'Common/utils';
import { localization } from '~features';

function createSelector(id) {
    return id ? (state) => localization.selectors.getTranslationString(state, id) : () => id;
}

export default function useTranslation(id, params = null, { notFoundValue = id } = {}) {
    const selector = useMemo(() => createSelector(id), [id]);
    const property = useSelector(selector);

    const defaultProp = useMemo(() => (property === id ? notFoundValue : property || notFoundValue), [
        property,
        id,
        notFoundValue,
    ]);

    const translation = useMemo(
        () => (params ? Formatter.property(defaultProp, typeof params === 'object' ? params : {}) : defaultProp),
        [defaultProp, params]
    );

    return translation;
}
