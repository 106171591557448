import { ReduxFeature } from 'Common/utils';
import * as actions from './actions';
import * as api from './api';
import * as selectors from './sel';
import reducers from './reducers';
import initialState from './state';

export default ReduxFeature.create({ actions, api, selectors, reducers, initialState });

export * as ProductApi from './api';
