import $ from 'jquery';

const ZERO_REVIEW_LABEL = '0 Reviews';

export default class RatingsController {
    static verifyStarRating() {
        let wrapperCounter = 1;

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                mutation.addedNodes.forEach((node) => {
                    if (node.nodeType === Node.ELEMENT_NODE && $(node).hasClass('yotpo-display-wrapper') && !$(node).attr('id')) {
                        $(node).attr('id', `wrapper-${wrapperCounter}`);

                        if ($(node).has('.standalone-bottomline').length) {
                            $(node)
                                .children('.standalone-bottomline')
                                .children('.yotpo-bottomline')
                                .children('.write-review-btn-hidden')
                                .text(ZERO_REVIEW_LABEL);
                        } else {
                            $(node).append(`
                                <div class="standalone-bottomline" data-source="default" tabindex="-1">
                                    <div class="yotpo-bottomline pull-left star-clickable" tabindex="0">
                                        <span class="yotpo-stars">
                                            <span class="yotpo-icon yotpo-icon-empty-star rating-star pull-left"></span>
                                            <span class="yotpo-icon yotpo-icon-empty-star rating-star pull-left"></span>
                                            <span class="yotpo-icon yotpo-icon-empty-star rating-star pull-left"></span>
                                            <span class="yotpo-icon yotpo-icon-empty-star rating-star pull-left"></span>
                                            <span class="yotpo-icon yotpo-icon-empty-star rating-star pull-left"></span>
                                        </span>
                                        <div class="yotpo-clr"></div>
                                    </div>
                                    <div class="yotpo-clr"></div>
                                </div>`);
                        }

                        wrapperCounter += 1;

                        const reviewQaLabelsContainer = $(node).find('.reviews-qa-labels-container').text();
                        const reviewQaLabel = $(node).find('.reviews-qa-label').text();

                        if (reviewQaLabelsContainer.length) {
                            $('.reviewCount').text(reviewQaLabelsContainer);
                        } else if (reviewQaLabel.length) {
                            $('.reviewCount').text(reviewQaLabel);
                        }
                    }
                });
            });
        });

        observer.observe(document.body, { childList: true, subtree: true });
    }
}
