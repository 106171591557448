import pick from 'lodash.pick';
import { logger } from 'Common/core';
import { Api, CreditCard } from 'Common/utils';
import { SHIPMENT, PAYMENT, ADDRESS } from 'Common/constants/fields';

const FORM_FIELDS = Object.values(PAYMENT).concat(Object.values(ADDRESS).map((s) => `${PAYMENT.address}.${s}`));

// Cart

export function fetchCart() {
    return Api.GET('/cart/GetCart');
}

// New Cart API

/**
 * @param {string|string[]} options.expand 'forms' | 'ordertotals' | 'shippingmethods' | 'savedshippingaddresses' | 'savedpayments'
 * @param {string|string[]} options.update 'lineitems' | 'shipping' | 'payment' | 'promotions'
 * @returns
 */
function cartApiOptonsQuery({ expand = '', update = '' } = {}) {
    if (!expand?.length && !update?.length) return undefined;

    const query = {};

    if (expand?.length) query.expand = typeof expand === 'string' ? expand.split(',') : expand;
    if (update?.length) query.update = typeof update === 'string' ? update.split(',') : update;

    return query;
}

/**
 * @param {object} [options]
 * @param {string|string[]} [options.expand]
 * @param {string|string[]} [options.update]
 */
export function getCurrentCart(options) {
    return Api.GET('/cart/current', cartApiOptonsQuery(options), { cache: false });
}

/**
 * @param {object} [options]
 * @param {string|string[]} [options.expand]
 * @param {string|string[]} [options.update]
 */
export function deleteCurrentCart(options) {
    return Api.DELETE('/cart/current', cartApiOptonsQuery(options));
}

/**
 * @param {number} shipmentId
 * @param {string} currentCode
 * @param {number} nextQuantity
 * @param {string} [nextCode]
 * @param {object} [options]
 * @param {string|string[]} [options.expand]
 * @param {string|string[]} [options.update]
 * @returns
 */
export function updateCartLineItem(shipmentId, currentCode, nextQuantity, nextCode = currentCode, options) {
    return Api.PUT(
        `/cart/current/form/0/shipment/${shipmentId}/lineItem/${currentCode}`,
        {
            code: nextCode,
            quantity: nextQuantity,
        },
        { params: cartApiOptonsQuery(options), safe: false }
    );
}

/**
 * @param {number} shipmentId
 * @param {array} lineItems
 * @param {string} lineItems[].currentCode
 * @param {number} lineItems[].quantity
 * @param {string} [lineItems[].code]
 * @param {object} [options]
 * @param {string|string[]} [options.expand]
 * @param {string|string[]} [options.update]
 */
export function updateCartLineItems(shipmentId, lineItems, options) {
    return Api.PUT(
        `/cart/current/form/0/shipment/${shipmentId}/lineItem`,
        { lineItems },
        { params: cartApiOptonsQuery(options), safe: false }
    );
}

/**
 * @param {number} shipmentId
 * @param {array} lineItems
 * @param {string} lineItems[].code
 * @param {number} lineItems[].quantity
 * @param {string} [lineItems[].productRecommendationId]
 * @param {object} [options]
 * @param {string|string[]} [options.expand]
 * @param {string|string[]} [options.update]
 */
export function createCartLineItems(lineItems, options) {
    return Api.POST(
        `/cart/current/form/0/lineItem`,
        lineItems.map((item) => ({
            ...item,
            productRecommendationId:
                typeof item.productRecommendationId === 'string'
                    ? item.productRecommendationId
                    : item.productRecommendationId == null
                    ? ''
                    : String(item.productRecommendationId),
        })),
        { params: cartApiOptonsQuery(options), safe: false }
    );
}

/**
 * @param {number} shipmentId
 * @param {string} code
 * @param {object} [options]
 * @param {string|string[]} [options.expand]
 * @param {string|string[]} [options.update]
 */
export function deleteCartLineItem(shipmentId, code, options) {
    return Api.DELETE(`/cart/current/form/0/shipment/${shipmentId}/lineItem/${code}`, cartApiOptonsQuery(options), {
        safe: false,
    });
}

/**
 * @param {number} shipmentId
 * @param {object} [options]
 * @param {string|string[]} [options.expand]
 * @param {string|string[]} [options.update]
 */
export function deleteCartLineItems(shipmentId, options) {
    return Api.DELETE(`/cart/current/form/0/shipment/${shipmentId}/lineItem`, cartApiOptonsQuery(options), {
        safe: false,
    });
}

/**
 * @param {string} email
 * @param {boolean} signup
 * @param {object} [options]
 * @param {string|string[]} [options.expand]
 * @param {string|string[]} [options.update]
 */
export function updateCartOrderEmail(email, signup, options) {
    return Api.PUT('/cart/current/orderemail', { email, signup }, { params: cartApiOptonsQuery(options) });
}

/**
 * @param {*} payment
 * @param {boolean} savePayment
 * @param {string} reCaptchaToken
 * @param {object} [options]
 * @param {string|string[]} [options.expand]
 * @param {string|string[]} [options.update]
 */
export function createCartOrderPayment(payment, savePayment, reCaptchaToken, options) {
    return Api.POST(
        `/cart/current/form/0/payment`,
        { payment, savePayment, reCaptchaToken },
        { params: cartApiOptonsQuery(options) }
    );
}

/**
 * @param {string} paymentMethodId
 * @param {object} [options]
 * @param {string|string[]} [options.expand]
 * @param {string|string[]} [options.update]
 */
export function deleteCartOrderPayment(paymentMethodId, cardNumber, options) {
    return Api.DELETE(`/cart/current/form/0/payment/${paymentMethodId}`, {
        ...cartApiOptonsQuery(options),
        cardNumber,
    });
}

/**
 * @param {string} couponCode
 * @param {object} [options]
 * @param {string|string[]} [options.expand]
 * @param {string|string[]} [options.update]
 */
export function createCartOrderPromotion(couponCode, options) {
    return Api.POST(`/cart/current/form/0/couponcode`, { couponCode }, { params: cartApiOptonsQuery(options) });
}

/**
 * @param {string} couponCode
 * @param {object} [options]
 * @param {string|string[]} [options.expand]
 * @param {string|string[]} [options.update]
 * @returns
 */
export function deleteCartOrderPromotion(couponCode, options) {
    return Api.DELETE(`/cart/current/form/0/couponcode/${couponCode}`, cartApiOptonsQuery(options));
}

/**
 *
 * @param {number} shipmentId
 * @param {object} update
 * @param {string} [update.address]
 * @param {boolean} [update.useDefault]
 * @param {string} [update.shippingMethodName]
 * @param {boolean} [update.isGift]
 * @param {string} [update.giftMessage]
 * @param {object} [options]
 * @param {string|string[]} [options.expand]
 * @param {string|string[]} [options.update]
 */
export function updateCartOrderShipment(
    shipmentId,
    { address = null, useDefault = false, shippingMethodName = null, isGift = false, giftMessage = null },
    options
) {
    return Api.PUT(
        `/cart/current/form/0/shipment/${shipmentId}`,
        { address, useDefault, shippingMethodName, isGift, giftMessage },
        { params: cartApiOptonsQuery(options) }
    );
}

// ---

export function updateCartQty(qtyChanges, options) {
    const shipments = {};
    let promise = Promise.resolve();

    for (const { itemCode, qty, shipmentId } of qtyChanges) {
        if (!shipments[shipmentId]) shipments[shipmentId] = [];
        shipments[shipmentId].push({ currentCode: itemCode, quantity: qty });
    }

    for (const shipmentId of Object.keys(shipments)) {
        promise = promise.then(() => updateCartLineItems(shipmentId, shipments[shipmentId], options));
    }
    return promise;
}

export function splitCart(addressChanges) {
    return Api.POST('/cart/SplitCart', {
        orderFormId: 0,
        shipments: addressChanges.shipments,
    });
}

// Shipment

export function getCountrySelectionData(siteId) {
    return Api.GET(`/countryregion/countries?siteid=${siteId}`);
}

export function getCountryRegionSelectionData(siteId, countryCode) {
    return Api.GET(`/countryregion/regions?countrycode=${countryCode}&siteid=${siteId}`);
}

export function verifyAddress(address) {
    return Api.POST('/AddressVerification/VerifyAddress', address, { safe: false });
}

export function updateShipmentMethod(shipmentId, shippingMethodName, options) {
    return updateCartOrderShipment(shipmentId, { shippingMethodName }, options);
}

export function updateShipmentGift(shipmentId, isGift, giftMessage, options) {
    return updateCartOrderShipment(shipmentId, { isGift, giftMessage }, options);
}

export function updateShipmentAddress(shipmentId, address, useDefault, options) {
    return updateCartOrderShipment(shipmentId, { address, useDefault }, options);
}

export function updateShipmentDetails(
    shipmentId,
    address,
    useDefault,
    shippingMethodName,
    isGift,
    giftMessage,
    options
) {
    return updateCartOrderShipment(
        shipmentId,
        { address, useDefault, shippingMethodName, isGift, giftMessage },
        options
    );
}

export function updateShipment(form, options) {
    const { [ADDRESS.defaultAddress]: useDefault, ...address } = pick(form[SHIPMENT.address], Object.values(ADDRESS));
    const {
        [SHIPMENT.isGift]: isGift,
        [SHIPMENT.giftMessage]: giftMessage,
        [SHIPMENT.shippingMethod]: shippingMethodName,
        [SHIPMENT.shipmentId]: shipmentId,
    } = form;

    return updateCartOrderShipment(
        shipmentId,
        { address, useDefault, isGift, giftMessage, shippingMethodName },
        options
    );
}

export function updateShipmentDetail(form, options) {
    const { [ADDRESS.defaultAddress]: useDefault, ...address } = pick(form[SHIPMENT.address], Object.values(ADDRESS));
    const {
        [SHIPMENT.isGift]: isGift,
        [SHIPMENT.giftMessage]: giftMessage,
        [SHIPMENT.shippingMethod]: shippingMethodName,
        [SHIPMENT.shipmentId]: shipmentId,
    } = form;

    return updateShipmentDetails(shipmentId, address, useDefault, shippingMethodName, isGift, giftMessage, options);
}

export function saveCustomerAddress(address) {
    return Api.PUT('/customer/saveAddress', {
        ...address,
        isShipping: true,
    });
}

export function getAvailableShippingMethods(ShipmentId, CountryCode, RegionCode) {
    return Api.POST('/Cart/GetAvailableShippingMethods', {
        ShipmentId,
        CountryCode,
        RegionCode,
    });
}

// Payment

export async function addPayment(paymentForm, reCaptchaToken = '', options) {
    const { [PAYMENT.savePayment]: savePayment, ...rest } = paymentForm;
    let payment = rest;

    if (!rest[PAYMENT.paymentType]) {
        return null;
    } else if (rest[PAYMENT.paymentType] === 'CreditCard') {
        const response = await getCreditCardPaymentFromForm(rest);

        if (response?.success) {
            payment = response.creditCard;
        } else {
            logger.warn(`CC tokenization failed: ${response?.message}`);
            throw new Error(response?.message);
        }
    }

    if (payment) {
        return createCartOrderPayment(payment, savePayment, reCaptchaToken, options);
    }
}

export function removePayment(paymentMethodId, cardNumber, options) {
    return deleteCartOrderPayment(paymentMethodId, cardNumber, options);
}

export async function getCreditCardPaymentFromForm(form) {
    return CreditCard.tokenizePaymentForm(pick(form, FORM_FIELDS));
}

// Order

export function placeOrder(token, cartId) {
    return Api.POST('/cart/PlaceOrder', { token, orderGroupId: cartId });
}

export function updateOrderEmail(email, signup = false, options) {
    return updateCartOrderEmail(email, signup, options);
}

export function validateApplePay(validationUrl, systemName, marketId) {
    return Api.POST('/ApplePayMerchantValidation/ValidateMerchant', {
        PaymentMethodSystemName: systemName,
        MarketId: marketId,
        ValidationUrl: validationUrl,
    });
}
