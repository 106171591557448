import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import $ from 'jquery';
import { NODE_ENV } from '$env';

function consumeData(el) {
    const data = $(el).data();

    if (NODE_ENV === 'production') {
        setTimeout(() => {
            Array.from(el.attributes).forEach((a) => {
                if (a.name.startsWith('data-')) {
                    el.removeAttribute(a.name);
                }
            });
        }, 1);
    }
    return data;
}

export default function registerComponent(name, Component, store) {
    function renderComponent(el, props) {
        ReactDOM.render(
            store ? (
                <Provider store={store}>
                    <Component {...props} />
                </Provider>
            ) : (
                <Component {...props} />
            ),
            el
        );
        return () => {
            ReactDOM.unmountComponentAtNode(el);
        };
    }

    if (!window.components) window.components = {};
    window.components[name] = renderComponent;

    $(`[data-component="${name}"]`).each(function renderComponentAttribute() {
        const { component, unwrapComponent = false, ...props } = consumeData(this);

        if (unwrapComponent && this.parentElement) {
            renderComponent(this.parentElement, props);
            setTimeout(() => this.remove());
        } else {
            renderComponent(this, props);
        }
    });
}
