/**
 * A facet value.
 * @typedef {Object} FacetValue
 * @property {string} Value - Facet value.
 * @property {boolean} Selected - Whether or not this value is selected.
 */

/**
 * A facet.
 * @typedef {Object} Facet
 * @property {string} Attribute - Attribute name.
 * @property {FacetValue[]?} FacetValues - List of facet values.
 * @property {FacetValue[]?} Values - List of facet values.
 */

const Facet = {
    /**
     * Retrieves the values for a facet.
     *
     * @param {Facet} facet
     * @returns FacetValue[]
     */
    values(facet) {
        return facet.FacetValues || facet.Values || [];
    },
    /**
     * Retrieves the attribute name for a facet.
     *
     * @param {Facet} facet
     * @returns string
     */
    attribute(facet) {
        return facet.Attribute || facet.PropertyName || '';
    },
    /**
     * Maps all values within a facet.
     *
     * @param {Facet} facet
     * @param {function (FacetValue): FacetValue} callback
     * @returns Facet
     */
    mapValues(facet, callback) {
        return {
            ...facet,
            [facet.FacetValues ? 'FacetValues' : 'Values']: Facet.values(facet).map(callback),
        };
    },
    /**
     * Updates a single value within a facet.
     *
     * @param {Facet} facet
     * @param {string} value
     * @param {function (FacetValue): FacetValue} callback
     * @returns Facet
     */
    updateValue(facet, value, callback) {
        return Facet.mapValues(facet, (facetValue) => (facetValue.Value === value ? callback(facetValue) : facetValue));
    },
    /**
     * Deselects a single value within a facet.
     *
     * @param {Facet} facet
     * @param {string} value
     * @param {function (FacetValue): FacetValue} callback
     * @returns Facet
     */
    deselectValue(facet, value) {
        return Facet.updateValue(facet, value, (facetValue) => ({
            ...facetValue,
            Selected: false,
        }));
    },
    /**
     * Deselects all values within a facet.
     *
     * @param {Facet} facet
     * @returns Facet
     */
    deselectAllValues(facet) {
        return Facet.mapValues(facet, (facetValue) => ({
            ...facetValue,
            Selected: false,
        }));
    },
};

export default Facet;
