import { Name, ExtForm } from '../utils';

export default class AccountSettingsController {
    static init(
        uid = 'settingsForm',
        { fNameId = 'FirstName', lNameId = 'LastName', fieldErrorClass = 'invalid-feedback' } = {}
    ) {
        const fields = [
            { id: fNameId, validator: Name.getMultiValidationError.bind(Name) },
            { id: lNameId, validator: Name.getMultiValidationError.bind(Name) },
        ];

        ExtForm.initForm(
            uid,
            fields,
            '/api/customer/SaveCustomerAccountSettings',
            {
                fNameId,
                lNameId,
                fieldErrorClass,
            },
            false
        );
    }
}
