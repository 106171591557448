import { createSelector } from 'reselect';

export const getUserState = (state) => state.user;

export const getBillingAddresses = createSelector(getUserState, (user = {}) => user.billing);

export const getShippingAddresses = createSelector(getUserState, (user = {}) => user.shipping);

export const getDefaultBilling = createSelector(getBillingAddresses, (billing = []) =>
    billing.find((address) => address.default)
);

export const getDefaultShipping = createSelector(getShippingAddresses, (shipping = []) =>
    shipping.find((address) => address.default)
);

export const getBillingById = createSelector(
    (_, id) => id,
    getBillingAddresses,
    (id, billing = []) => billing.find((address) => address.id === id)
);

export const getShippingById = createSelector(
    (_, id) => id,
    getShippingAddresses,
    (id, shipping = []) => shipping.find((address) => address.id === id)
);
