import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';

export default function StaticLink({ className, linkText, url }) {
    return (
        <a href={url} className={cx('StaticLink', className)}>
            {linkText}
        </a>
    );
}

StaticLink.propTypes = {
    className: px.string,
    linkText: px.string,
    url: px.string,
};
