const pvars = new WeakMap();

let lastLocation = { ...window.location };
let watchingState = false;
let watchingHash = false;

const hashListeners = [];
const pathListeners = [];
const paramListeners = [];
const globalListeners = [];

function onLocationChange() {
    const ref = Url.current;
    const hashChange = lastLocation.hash !== window.location.hash;
    const paramChange = lastLocation.search !== window.location.search;
    const pathChange = lastLocation.pathname !== window.location.pathname;
    const globalChange = lastLocation.href !== window.location.href;

    lastLocation = { ...window.location };

    if (globalChange) for (const listener of globalListeners) listener(ref);
    if (hashChange) for (const listener of hashListeners) listener(ref);
    if (paramChange) for (const listener of paramListeners) listener(ref);
    if (pathChange) for (const listener of pathListeners) listener(ref);
}

function onHashChange() {
    if (lastLocation.hash !== window.location.hash) {
        const ref = Url.current;

        lastLocation.hash = window.location.hash;

        for (const listener of globalListeners) listener(ref);
        for (const listener of hashListeners) listener(ref);
    }
}

function checkStart() {
    if (!watchingHash && (hashListeners.length || globalListeners.length)) {
        window.addEventListener('hashchange', onHashChange);
    }
    if (
        !watchingState &&
        (hashListeners.length || globalListeners.length || paramListeners.length || pathListeners.length)
    ) {
        window.addEventListener('popstate', onLocationChange);
    }
}

function checkStop() {
    if (watchingHash && !hashListeners.length && !globalListeners.length) {
        window.removeEventListener('hashchange', onHashChange);
    }
    if (
        watchingState &&
        !hashListeners.length &&
        !globalListeners.length &&
        !paramListeners.length &&
        !pathListeners.length
    ) {
        window.removeEventListener('popstate', onLocationChange);
    }
}

export default class Url {
    static get current() {
        return this.create();
    }

    static create(url = window.location.href, base = window.location.href) {
        return new this(url, base);
    }

    static addToRelativeUrl(url, query) {
        return this.create(url).addSearch(query).href;
    }

    static onHashChange(listener) {
        hashListeners.push(listener);
        checkStart();
    }

    static offHashChange(listener) {
        const i = hashListeners.indexOf(listener);

        if (i >= 0) {
            hashListeners.splice(i, 1);
            if (!hashListeners.length) checkStop();
        }
    }

    static onPathChange(listener) {
        pathListeners.push(listener);
        checkStart();
    }

    static offPathChange(listener) {
        const i = pathListeners.indexOf(listener);

        if (i >= 0) {
            pathListeners.splice(i, 1);
            if (!pathListeners.length) checkStop();
        }
    }

    static onParamChange(listener) {
        paramListeners.push(listener);
        checkStart();
    }

    static offParamListeners(listener) {
        const i = paramListeners.indexOf(listener);

        if (i >= 0) {
            paramListeners.splice(i, 1);
            if (!paramListeners.length) checkStop();
        }
    }

    static onChange(listener) {
        globalListeners.push(listener);
        checkStart();
    }

    static offChange(listener) {
        const i = globalListeners.indexOf(listener);

        if (i >= 0) {
            globalListeners.splice(i, 1);
            if (!globalListeners.length) checkStop();
        }
    }

    static isAbsolute(href) {
        return /^(https?:)?\/\//.test(href);
    }

    constructor(url = window.location.href, base = window.location.href) {
        const { pathname, hash, searchParams: params, origin, host } =
            url instanceof URL || url instanceof Url ? url : new URL(url, base || undefined);

        pvars.set(this, { origin, params, pathname, hash, host });
    }

    get params() {
        return pvars.get(this).params;
    }

    get host() {
        return pvars.get(this).host;
    }

    get searchParams() {
        return this.params;
    }

    get origin() {
        return pvars.get(this).origin;
    }

    get pathname() {
        const pathname = pvars.get(this).pathname;

        return pathname[0] === '/' ? pathname : `/${pathname}`;
    }

    get hash() {
        const hash = pvars.get(this).hash;

        return hash?.length > 1 && hash[0] === '#' ? hash : !hash || hash === '#' ? '' : `#${hash}`;
    }

    get hashes() {
        const hash = this.hash;

        return hash.slice(1).split(',').filter(Boolean);
    }

    get search() {
        const str = this.params.toString();

        return str ? `?${str}` : str;
    }

    get href() {
        return `${this.origin}${this.pathname}${this.search}${this.hash}`;
    }

    addSearch(searchStr, append = false) {
        const params = new URLSearchParams(searchStr);
        const copy = new URLSearchParams(this.search);

        for (const [k, v] of params.entries()) {
            if (!append && copy.has(k)) {
                copy.delete(k);
                this.params.delete(k);
            }
            this.params.append(k, v);
        }
        return this;
    }

    addParam(key, value, multi = false) {
        if (multi) {
            if (!this.hasParam(key, value)) this.params.append(key, value);
        } else if (Array.isArray(value)) {
            value.forEach((v) => {
                if (!this.hasParam(key, v)) this.params.append(key, v);
            });
        } else {
            this.params.set(key, value);
        }
        return this;
    }

    toggleParam(key, value, multi = false) {
        if (this.hasParam(key, value)) {
            this.delParam(key, value);
        } else if (multi) {
            this.params.append(key, value);
        } else {
            this.params.set(key, value);
        }
        return this;
    }

    getParam(key, multi = false) {
        if (multi) {
            const list = [];

            for (const [k, v] of this.params.entries()) {
                if (key === k) list.push(v);
            }
            return list;
        }
        return this.params.get(key);
    }

    consumeParam(key, multi = false) {
        const out = this.getParam(key, multi);

        this.delParam(key);
        return out;
    }

    hasParam(key, value = null) {
        if (value == null) return this.params.has(key);
        for (const [k, v] of this.params.entries()) {
            if (k === key && v === value) return true;
        }
        return false;
    }

    delParam(key, ...values) {
        if (values.length) {
            const arr = Array.isArray(values[0]) ? values[0] : values;
            const list = [];

            for (const [k, v] of this.params.entries()) {
                if (k === key && !arr.includes(v)) list.push(v);
            }
            this.params.delete(key);
            list.forEach((v) => this.params.append(key, v));
        } else {
            this.params.delete(key);
        }
        return this;
    }

    addPath(path = '/') {
        let next = this.pathname;

        if (path && path !== '/') {
            if (next.endsWith('/') && path[0] === '/') {
                next += path.slice(1);
            } else if (!next.endsWith('/') && path[0] !== '/') {
                next += `/${path}`;
            } else {
                next += path;
            }
        }
        this.setPath(next);
        return this;
    }

    delPath(part = '/') {
        if (part) {
            let nextPath = this.pathname.slice(0, this.pathname.indexOf(part));

            if (nextPath.endsWith('/')) nextPath = nextPath.slice(0, -1);
            return this.setPath(nextPath);
        }
        return this;
    }

    setOrigin(origin) {
        if (origin) {
            pvars.get(this).origin = origin;
            return this;
        }
        throw new Error('Cannot have empty origin');
    }

    setPath(path = '/') {
        pvars.get(this).pathname = path || '/';
        return this;
    }

    clearPath() {
        return this.setPath();
    }

    setHash(hash = '') {
        pvars.get(this).hash = hash;
        return this;
    }

    clearHash() {
        return this.setHash();
    }

    addHash(hash, multi = false) {
        const hashes = this.hashes;

        if (multi || !this.hashes.includes(hash)) this.setHash(hashes.concat(hash).join(','));
        return this;
    }

    hashCount(hash) {
        return this.hashes.filter((h) => h === hash).length;
    }

    hasHash(hash) {
        return this.hashes.includes(hash);
    }

    delHash(hash, first = false) {
        const hashes = this.hashes;
        const i = hashes.indexOf(hash);

        if (i >= 0) {
            const next = first ? hashes.slice(0, i).concat(hashes.slice(i + 1)) : hashes.filter((h) => h !== hash);

            this.setHash(next.join(','));
        }
        return this;
    }

    setSearch(str) {
        pvars.get(this).params = new URLSearchParams(str);
        return this;
    }

    clearSearch() {
        return this.setSearch();
    }

    clearParams() {
        return this.clearSearch();
    }

    apply(pushState = false, title = window.title) {
        if (pushState) {
            window.history.pushState({}, title, this.href);
        } else {
            window.history.replaceState({}, title, this.href);
        }

        onLocationChange();

        return this;
    }
}
