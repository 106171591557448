import { v4 } from 'uuid';
import * as types from './types';
import * as api from './api';
import state from './state';
import { getDefaultBilling, getDefaultShipping, getShippingById, getBillingById } from './selectors';

export function setUserState(user = state) {
    return { type: types.SET_USER, payload: user };
}

export function clearUserState() {
    return setUserState();
}

export function fetchUser() {
    return async (dispatch) => {
        const user = await api.fetchUser();

        if (user) {
            dispatch(setUserState(user));
        }
    };
}

export function updateShippingAddress(address) {
    return async (dispatch) => {
        const next = address; // await api.updateShippingAddress(address);

        if (next) {
            dispatch({
                type: types.UPDATE_SHIPPING_ADDRESS,
                payload: next,
            });
        }
    };
}

export function updateBillingAddress(address) {
    return async (dispatch) => {
        const next = address; // await api.updateBillingAddress(address);

        if (next) {
            dispatch({
                type: types.UPDATE_BILLING_ADDRESS,
                payload: next,
            });
        }
    };
}

export function setDefaultShipping(id) {
    return (dispatch, getState) => {
        const curState = getState();
        const sel = getShippingById(curState, id);
        const def = sel && (sel.default ? sel : getDefaultShipping(curState));

        if (sel && def !== sel) {
            dispatch(updateShippingAddress({ ...sel, default: true }));
            dispatch(updateShippingAddress({ ...def, default: false }));
        }
    };
}

export function setDefaultBilling(id) {
    return (dispatch, getState) => {
        const curState = getState();
        const sel = getBillingById(curState, id);
        const def = sel && (sel.default ? sel : getDefaultBilling(curState));

        if (sel && def !== sel) {
            dispatch(updateBillingAddress({ ...sel, default: true }));
            dispatch(updateBillingAddress({ ...def, default: false }));
        }
    };
}

export function addShippingAddress(data) {
    const address = { ...data, id: v4() };

    return async (dispatch) => {
        const next = address; // await api.addShippingAddress(address);

        if (next) {
            dispatch({
                type: types.ADD_SHIPPING_ADDRESS,
                payload: next,
            });
        }
    };
}

export function addBillingAddress(data) {
    const address = { ...data, id: v4() };

    return async (dispatch) => {
        const next = address; // await api.addBillingAddress(address);

        if (next) {
            dispatch({
                type: types.ADD_BILLING_ADDRESS,
                payload: next,
            });
        }
    };
}

export function removeShippingAddress(id) {
    return async (dispatch) => {
        if (id /* await api.removeShippingAddress(address) */) {
            dispatch({
                type: types.DEL_SHIPPING_ADDRESS,
                payload: id,
            });
        }
    };
}

export function removeBillingAddress(id) {
    return async (dispatch) => {
        if (id /* await api.removeBillingAddress(address) */) {
            dispatch({
                type: types.DEL_BILLING_ADDRESS,
                payload: id,
            });
        }
    };
}
