export const SET_USER = 'user:set';

export const SET_SHIPPING_ADDRESSES = 'user:shipping:set';
export const ADD_SHIPPING_ADDRESS = 'user:shipping:add';
export const DEL_SHIPPING_ADDRESS = 'user:shipping:del';
export const UPDATE_SHIPPING_ADDRESS = 'user:shipping:update';
export const SET_DEFAULT_SHIPPING = 'user:shipping:default';

export const SET_BILLING_ADDRESSES = 'user:billing:set';
export const ADD_BILLING_ADDRESS = 'user:billing:add';
export const DEL_BILLING_ADDRESS = 'user:billing:del';
export const UPDATE_BILLING_ADDRESS = 'user:billing:update';
export const SET_DEFAULT_BILLING = 'user:billing:default';
