export default async function getImageSource([source, ...rest] = [], defaultSource = '', { minWidth = 1 } = {}) {
    if (!source) return defaultSource;

    return new Promise((res) => {
        const image = new Image();

        image.onerror = () => {
            res(getImageSource(rest, defaultSource, { minWidth }));
        };
        image.onload = () => {
            if (image.width >= minWidth) {
                res(source);
            } else {
                res(getImageSource(rest, defaultSource, { minWidth }));
            }
        };
        image.src = source;
    });
}
