export const SET_STATE = 'order:set';
export const UPDATE_ITEM_QTY = 'order:form:shipment:lineitem:qty:set';
export const UPDATE_ITEM_ADDRESS = 'order:form:shipment:lineitem:address:set';
export const ADD_SAVE_ADDRESS = 'order:form:cart:savedshippingaddresses:add';
export const CLEAR_CHANGES = 'order:changes:clear';
export const SET_USER_MESSAGE = 'order:userMessage:set';
export const SET_PAYMENT_TOKEN = 'order:paymentTokens:set';
export const ADD_VALIDATION_ISSUE = 'order:validationIssues:add';
export const CLEAR_VALIDATION_ISSUE = 'order:validationIssues:clear';
export const SET_SHIPPING_METHODS = 'order:shippingMethods:set';
export const SET_IS_UPDATING = 'order:isPerformingCartOperation:set';
export const UPDATE_CONFIG = 'order:config:update';
