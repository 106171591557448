import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useTranslation } from 'Common/hooks';
import SvgIcon from './SvgIcon';

export default function Icon({
    icon,
    label,
    labelProps,
    name,
    sheet,
    style,
    color,
    size,
    rotate,
    className,
    ...props
}) {
    const translated = useTranslation(label, labelProps);

    const [svg, iconSheet, iconName] = React.useMemo(() => {
        if (name) {
            return [true, sheet, name];
        }
        if (icon && /\bicons\b/.test(icon)) {
            const mSheet = icon.match(/\bicons-([\w-]+)\b/);
            const mName = icon.match(/\bicon-([\w-]+)\b/);

            return [!!mName, mSheet ? mSheet[1] : undefined, mName ? mName[1] : undefined];
        }
        return [false];
    }, [icon, sheet, name]);

    const css = React.useMemo(() => {
        const s = { ...style };

        if (color) s.color = color;
        if (size) s.fontSize = size;
        if (rotate) {
            s.transform = typeof rotate === 'number' ? `rotate(${rotate}deg)` : `rotate(${rotate})`;
        }
        return s;
    }, [style, color, size, rotate]);

    const aria = React.useMemo(() => (translated ? { 'aria-label': translated } : { 'aria-hidden': 'true' }), [
        translated,
    ]);

    return svg ? (
        <SvgIcon className={cx('Icon', className)} sheet={iconSheet} name={iconName} style={css} {...props} {...aria} />
    ) : (
        <i className={cx('Icon', className, icon)} style={css} {...props} {...aria} />
    );
}

Icon.propTypes = {
    labelProps: px.object,
    label: px.string,
    name: px.string,
    sheet: px.string,
    size: px.oneOfType([px.number, px.string]),
    color: px.string,
    style: px.object,
    icon: px.string,
    className: px.string,
    rotate: px.oneOfType([px.number, px.string]),
};
