export const SET_DETAILS = 'item:details:set';
export const UPDATE_DETAILS = 'item:details:update';
export const SET_SELECTED_MEDIA = 'item:selectedMedia:set';
export const SELECT_CHILD = 'item:child:select';
export const SET_QUANTITY = 'item:quantity';
export const SELECT_FACET = 'item:facet:select';
export const SELECT_MULTI_FACET = 'item:facet:select:multi';
export const CLEAR_FACET = 'item:facet:clear';
export const SET_CHILD = 'item:child:set';
export const DESELECT_ALL_FACET_VALUES = 'item:facet:deselectAll';
export const SET_SELECTED_CODE = 'item:selectedCode:set';
export const ADD_PRODUCT_ENTRIES = 'item:byId:add';
export const UPDATE_PRODUCT_ENTRIES_QUANTITY = 'item:byId:quantity'
export const SELECT_CHILD_ATTRIBUTES = 'item:facet:child:set';
