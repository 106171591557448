import { GTM } from 'Common/utils';

export default class GoogleTagManagerController {
    static init(epiForms) {
        if (typeof epiForms !== 'undefined') {
            epiForms(document).ready(() => {
                epiForms('.EPiServerForms').on('formsSubmitted', (event) => {
                    if (event.isFinalizedSubmission && event.isSuccess) {
                        if (typeof dataLayer !== 'undefined') {
                            const formCategory = event.target?.elements?.gtmCategoryName?.value;
                            const formName = event.target?.elements?.gtmFormName?.value;

                            GTM.updateDataLayer(GTM.mapEntityToFormSubmission(formCategory, formName));
                        }
                    }
                });
            });
        }
    }
}
