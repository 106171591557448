import { createSelector } from 'reselect';

export const getLocalization = (state = {}) => state.localization;

export const getStringMap = createSelector(getLocalization, ({ strings = {} } = {}) => strings);

export const getTranslationString = createSelector(
    (_, id) => id,
    getStringMap,
    (id, map) => map[id] || id
);
