import $ from 'jquery';
import { getAntiForgeryToken } from 'Common/utils/Api';

function setLoading(uid, isLoading) {
    if (isLoading) {
        $(`#${uid} > .LoaderComponent`).removeAttr('style');
        $(`#${uid} .pointsRedemptionForm input, #${uid} .pointsRedemptionForm button`).attr('disabled', 'disabled');
    } else {
        $(`#${uid} > .LoaderComponent`).attr('style', 'display: none');
        $(`#${uid} .pointsRedemptionForm button`).removeAttr('disabled');
    }
}

function repopulateRadioButtons(redemptionOptions = []) {
    redemptionOptions.forEach((option) => {
        const input = $(`#${option.Value}`);

        input.prop('disabled', option.Disabled);
        if (option.Disabled) {
            input.prop('checked', false);
        } else {
            input.prop('checked', option.Selected);
        }
    });
}

export default class RewardsController {
    static onPointsRedeeming(uid, xhr) {
        xhr.setRequestHeader('RequestVerificationToken', getAntiForgeryToken());
        setLoading(uid, true);
    }

    static onPointsRedemption(uid, response = {}) {
        const { message, redemptionOptionsList: redemptionOptions, currentPointsText } = response;

        $(`#${uid} .pointsRedemptionForm`)[0].reset();
        $(`#${uid}-message .resultMessage`).removeClass('d-none');
        $(`#${uid}-message .resultMessage`).html(message);
        repopulateRadioButtons(redemptionOptions);
        $(`#${uid} .currentPointsTotal`).html(currentPointsText);
        setLoading(uid, false);
    }

    static validateForm(uid) {
        const selectedOption = $(
            `#${uid} .pointsRedemptionForm input[name='redemptionOptionId']:checked:not(:disabled)`
        );

        if (selectedOption.length > 0) {
            $(`#${uid} .pointsRedemptionForm button[type=submit]`).removeAttr('disabled');
        } else {
            $(`#${uid} .pointsRedemptionForm button[type=submit]`).attr('disabled', 'disabled');
        }
    }
}
